import React, { useState } from 'react'
import clsx from 'clsx'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import CookieIcon from '@mui/icons-material/Cookie'
import CookieOutlineIcon from '@mui/icons-material/CookieOutlined'

import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => {
  return {
    ProfileMenu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up(960)]: {
        '& > button:not(:first-child)': {
          marginTop: 12,
        },
      },
      [theme.breakpoints.down(960)]: {
        flexDirection: 'row',
        gap: theme.spacing(2),
      },
    },

    ProfileMenu_Button: {
      backgroundColor: '#fff',
      opacity: 0.8,
      height: 60,
      width: 'min-content',
      fontSize: 28,
      letterSpacing: '0.01px',

      '& .MuiButton-iconSizeLarge > *:first-child': {
        fontSize: 42,
      },

      [theme.breakpoints.down(1600)]: {
        fontSize: '14px',
        height: '40px',
        paddingLeft: '10px',
        paddingRight: '10px',
        '& .MuiButton-iconSizeLarge > *:first-child': {
          fontSize: '36px',
        },
      },
      [theme.breakpoints.down(960)]: {
        fontSize: '0',
        borderRadius: '100%',
        width: '80px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        '& span.MuiButton-startIcon': {
          margin: '0',
        },
      },
      [theme.breakpoints.down(700)]: {
        width: '50px',
        height: '50px',
        minWidth: '50px',
      },
    },
    ProfileMenu_Button__selected: {
      marginLeft: 48,
      opacity: 1,
      [theme.breakpoints.down(1600)]: {
        marginLeft: '15px',
      },
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }
})

const ProfileMenu = () => {
  const c = useStyles()
  const intl = useIntl()
  const history = useHistory()
  const ITEMS = [
    // { title: 'Achievements', icon: LocalCafeIcon, selectedIcon: LocalCafeOutlinedIcon },
    {
      title: intl.formatMessage({ id: 'profile.settings' }),
      icon: SettingsIcon,
      selectedIcon: SettingsOutlinedIcon,
      target: '/profile',
    },
    {
      title: 'Cookies',
      icon: CookieIcon,
      selectedIcon: CookieOutlineIcon,
      target: '/cookies',
    },
  ]
  const [selected, setSelected] = useState('')

  useEffect(() => {
    let item = ITEMS.find(i => i.target === history.location.pathname)
    if (item) {
      setSelected(item.title)
    } else {
      setSelected(ITEMS[0].title)
    }
  }, [])

  useEffect(() => {
    if (selected !== '') {
      let item = ITEMS.find(i => i.title === selected)
      history.replace(item.target)
    }
  }, [selected])

  return (
    <div className={c.ProfileMenu}>
      {ITEMS.map(item => {
        const isSelected = selected === item.title
        const Icon = isSelected ? item.icon : item.selectedIcon
        return (
          <Button
            key={item.title}
            variant='contained'
            color='inherit'
            size='large'
            className={clsx(
              c.ProfileMenu_Button,
              isSelected && c.ProfileMenu_Button__selected
            )}
            onClick={() => setSelected(item.title)}
            startIcon={<Icon />}
          >
            {item.title}
          </Button>
        )
      })}
    </div>
  )
}

export default ProfileMenu
