import { Backdrop, ClickAwayListener, Grid, Paper } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  getPremiumOffer,
  selectPremiumOffer,
  selectPremiumOfferStatus,
} from '@services/premiumOffer/slice'
import { STATUSES } from '@constants/slices'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  PremiumOffer_Card: {
    minHeight: 200,
    minWidth: 300,
    maxWidth: 'min(600px, 90vw)',
    padding: theme.spacing(2),
  },
  PremiumOffer_IconContainer: {
    justifyContent: 'flex-end',
  },
  PremiumOffer_Icon: {
    fontSize: 32,
    cursor: 'pointer',
  },
}))

const PremiumOffer = ({ isOpen, closeCallback }) => {
  const c = useStyles()
  const dispatch = useDispatch()
  const offer = useSelector(selectPremiumOffer)
  const offerStatus = useSelector(selectPremiumOfferStatus)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (offerStatus !== STATUSES.LOADED) {
      dispatch(getPremiumOffer())
    }
  }, [])

  return (
    <Backdrop open={isOpen} style={{ zIndex: 3 }}>
      <Paper className={c.PremiumOffer_Card}>
        <Grid container className={c.PremiumOffer_IconContainer}>
          <CloseIcon className={c.PremiumOffer_Icon} onClick={closeCallback}></CloseIcon>
        </Grid>
        {offerStatus === STATUSES.LOADED && (
          <div
            className='plakos-premium-offer-container'
            dangerouslySetInnerHTML={{ __html: offer.content.rendered }}
          ></div>
        )}
      </Paper>
    </Backdrop>
  )
}

export default PremiumOffer
