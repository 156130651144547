import React from 'react'

import coinRight from '@assets/coin_right.png'
import { Grid, Icon, Tooltip, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { COIN_SHOP_URL } from '@constants/constants'
import { useIntl } from 'react-intl'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  UserCoins_Wrapper: {
    width: 'auto',
    flexShrink: 1,
    cursor: 'pointer',
  },
  UserCoins_AddMoreIcon: {
    color: 'white',
    fontSize: '32px',
    [theme.breakpoints.down('md')]: { fontSize: '24px' },
  },
  UserCoins_CoinValue: {
    fontSize: '28px',
    color: 'white',
    [theme.breakpoints.down('md')]: { fontSize: '22px' },
  },
  UserCoins_CoinImageC: {
    left: '-16px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '42px',
  },
  UserCoins_Circle: {
    height: '71px', // 71.5
    width: '57px', // 57,2
    backgroundColor: 'white',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      height: '46px',
      width: '38px',
    },
  },
  UserCoins_CoinImage: {
    height: '58px',
    width: '58px',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      height: '38px',
      width: '38px',
    },
  },
  UserCoins_Background: {
    backgroundColor: theme.palette.secondary.variant3,
    padding: `0px ${theme.spacing(3)} 0px 4px`,
    margin: `${theme.spacing(1)} 0px`,
    borderTopLeftRadius: '32px',
    borderBottomLeftRadius: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxHeight: '36px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '28px',
    },
  },
}))

const UserCoins = ({ coins }) => {
  const c = useStyles()
  const intl = useIntl()
  const handleClick = () => {
    window.open(COIN_SHOP_URL, '_blank')
  }
  return (
    <Tooltip title={intl.formatMessage({ id: 'shop.addCoins' })}>
      <Grid
        container
        className={c.UserCoins_Wrapper}
        alignItems='center'
        justifyContent='center'
        onClick={handleClick}
        wrap='nowrap'
      >
        <div className={c.UserCoins_Background}>
          <AddCircleIcon className={c.UserCoins_AddMoreIcon}></AddCircleIcon>
          <Typography className={c.UserCoins_CoinValue}>{coins}</Typography>
        </div>
        <div className={c.UserCoins_CoinImageC}>
          <div className={c.UserCoins_Circle}></div>
          <img className={c.UserCoins_CoinImage} src={coinRight}></img>
        </div>
      </Grid>
    </Tooltip>
  );
}

export default UserCoins
