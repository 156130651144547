import React from 'react'

import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import SearchInput from '@components/molecules/Search/SearchInput'

const myStyles = makeStyles(theme => ({
  MobileSearch: {
    marginTop: '16px',
    [theme.breakpoints.up(960)]: {
      display: 'none',
    },
  },
}))

const MobileSearch = () => {
  const c = myStyles()

  return (
    <Box className={c.MobileSearch}>
      <Grid item container direction='row' justifyContent='flex-start' alignItems='center'>
        <SearchInput isMobile />
      </Grid>
    </Box>
  );
}

export default MobileSearch
