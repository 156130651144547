import React from 'react'
import { makeStyles } from '@mui/styles'

import { useHistory } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CardActionArea from '@mui/material/CardActionArea'

import BorderLinearProgress from '../atoms/BorderLinearProgress'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  NavigationCard: {},
  NavigationCard_CardAction: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  NavigationCard_MainContainer: {
    boxShadow: 'none',
    padding: theme.spacing(2),
    paddingBottom: 0,
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  NavigationCard_Title: {
    fontSize: '20px',
    lineHeight: '30px',
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  NavigationCard_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCard_TypeOfTest: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    paddingBottom: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCard_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  NavigationCard_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  NavigationCard_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
}))

const NavigationCard = ({
  typeOfTest = '',
  title = '',
  text = '',
  progress = 0,
  buttonText = '',
  buttonLink = '',
  disabled = false,
}) => {
  const c = useStyles()
  const history = useHistory()

  return (
    <>
      <CardActionArea
        onClick={() => history.push(buttonLink)}
        className={c.NavigationCard_CardAction}
        disabled={disabled}
      >
        <Paper className={c.NavigationCard_MainContainer} square>
          <Typography className={c.NavigationCard_TypeOfTest}> {typeOfTest} </Typography>
          <Typography className={clsx(c.NavigationCard_Title, c.NavigationCard_OneLine)}>
            {title}
          </Typography>
          <Typography className={clsx(c.NavigationCard_Text, c.NavigationCard_OneLine)}>
            {text}
          </Typography>
          <Typography className={c.NavigationCard_Button} color='primary'>
            {buttonText}
          </Typography>
        </Paper>
      </CardActionArea>
      <BorderLinearProgress variant='determinate' value={progress * 100} />
    </>
  )
}

export default NavigationCard
