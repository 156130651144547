import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectTopic, selectTopicStatus, getTopic, putProgress, progress } from './slice'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import DoneButton from '@components/atoms/DoneButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import {
  getCourseContent,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
  updateCourseTree,
  updateProgress,
} from '@services/courseOverview/slice'
import { setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  Topic: {},
  Topic_ButtonContainer: {
    margin: `${theme.spacing(4)} 0px`,
  },
  Topic_ProgressText: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}))

const LeftSideTopic = ({ topic, handleProgress, courseId }) => {
  const c = useStyles()
  const intl = useIntl()
  const { progress, title, children } = topic
  return (
    <>
      <SideHeader
        courseId={courseId}
        content={topic}
        type={intl.formatMessage({ id: 'shared.topic' })}
      />
      {children.length === 0 && (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          direction='column'
          className={c.Topic_ButtonContainer}
        >
          <Typography className={c.Topic_ProgressText}>
            <FormattedMessage
              id={progress !== 1 ? 'shared.progressDone' : 'shared.progressUndo'}
            ></FormattedMessage>
          </Typography>
          <DoneButton
            onClick={handleProgress}
            progress={progress}
            title={title}
            id={topic?.id}
          ></DoneButton>
        </Grid>
      )}
    </>
  )
}

const Topic = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, topicId } = useParams()
  const courseStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const topic = useSelector(selectCurrentContent)
  const [checked, setChecked] = useState(false)
  const topicStatus = topic && topicId == topic.id ? STATUSES.LOADED : STATUSES.LOADING

  useEffect(() => {
    if (topicStatus === STATUSES.LOADING) {
      dispatch(getCourseContent(courseId, topicId))
    }
  }, [dispatch, courseId, topicId, topicStatus])

  useEffect(() => {
    if (!topic || topic.id != topicId) {
      setChecked(false)
      return
    }
    if (
      STATUSES.LOADED === topicStatus &&
      courseTree &&
      courseTree.id == courseId &&
      !topic.introduction &&
      topic.children.length > 0
    ) {
      const child = topic.children[0]
      history.replace(
        `/${child.type === 'test' ? 'test' : 'topic'}/${courseTree.id}/${child.id}`
      )
    } else if (!checked) {
      setPageTitle(topic.type, topic.title)
      setChecked(true)
    }
  }, [courseTree, topic, topicStatus])

  const handleProgress = updatedProgress => {
    //Call to real API
    dispatch(putProgress(courseId, topicId, updatedProgress))
  }

  return (
    <LayoutWithoutImage
      status={courseStatus}
      courseTree={courseTree}
      currentContent={topic}
    >
      <LeftSideTopic courseId={courseId} topic={topic} handleProgress={handleProgress} />
    </LayoutWithoutImage>
  )
}

export default Topic
