import { CardActionArea, Grid, Paper, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import MenuIcon from '@mui/icons-material/MenuOpenRounded'
import { FormattedMessage } from 'react-intl'
import ContentEntryRow from '@components/atoms/CourseOverviewMenu/ContentEntryRow'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCourseOverviewIsOpen,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
  setClose,
} from '@services/courseOverview/slice'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  CourseOverview_Maincontainer: {
    margin: `0px ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(0)}`,
    width: `calc(100% - ${theme.spacing(4)})`,
    background: 'none',
    [theme.breakpoints.down('lg')]: {
      margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
      width: `calc(100% - ${theme.spacing(6)})`,
    },
  },
  CourseOverview_Maincontainer__withoutMargin: {
    width: '100%',
    background: 'none',
    marginBottom: theme.spacing(2),
  },
  CourseOverview_HeaderShape: {
    display: 'inline-flex',
    width: 'auto',
    minWidth: '150px',
  },
  CourseOverview_Header: {
    padding: ` 0px ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.variant2,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    boxShadow:
      '0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
  },
  CourseOverview_Seperator: {
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
    height: 0,
    padding: '4px 0px',
  },
  CourseOverview_Icon: {
    color: 'white',
    transform: 'rotate(180deg)',
  },
  CourseOverview_Text: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontSize: 22,
    fontWeight: 500,
  },
  CourseOverview_MainInsetBlock: {
    margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
  },
  CourseOverview_InsetBlock: {
    margin: `0px 0px ${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  CourseOverview_LeafContainer: {
    marginTop: theme.spacing(1),
  },
  CourseOverview_LeafInsetBlock: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 0 0 L 0 100 ' fill='none' stroke='${encodeURIComponent(
      theme.palette.secondary.main
    )}' stroke-width='4' stroke-dasharray='5 15' stroke-dashoffset='0' stroke-linecap='square' /%3E%3C/svg%3E")`,
    backgroundRepeat: 'repeat-y',
    paddingLeft: theme.spacing(1),
    margin: `0px 0px 0px ${theme.spacing(2)}`,
  },
}))

const Seperator = ({ width }) => {
  const c = useStyles()
  return <div className={c.CourseOverview_Seperator} style={{ width: `${width}%` }}></div>
}

const CourseOverview = ({ closeCallback, isMobileView }) => {
  const c = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const courseTree = useSelector(selectCourseTree)
  const currentContent = useSelector(selectCurrentContent)
  const courseStatus = useSelector(selectCourseTreeStatus)
  const isOpen = useSelector(selectCourseOverviewIsOpen)
  const theme = useTheme()
  const [openLessons, setOpenLessons] = useState([])
  const [openTopics, setOpenTopics] = useState([])
  const { title, children, id } = courseTree

  const handleNavigation = content => {
    if (isMobileView && isOpen) {
      dispatch(setClose())
    }
    const { type } = content
    let path = ''
    switch (type) {
      case 'course':
        path = `/course/${id}`
        break
      case 'lesson':
        path = `/lesson/${id}/${content.id}`
        break
      case 'topic':
        path = `/topic/${id}/${content.id}`
        break
      case 'test':
        path = `/test/${id}/${content.id}`
        break
      case 'notepad':
        path = `/notepad/${content.id}`
        break
      default:
        path = '/'
        break
    }

    if (isMobileView) history.replace(path)
    else history.push(path)
  }
  const contentPath = []
  let elem = currentContent
  while (elem) {
    contentPath.push(elem.id)
    elem = elem.surroundingContent
  }

  const isInPath = contentId => {
    return contentPath.includes(contentId)
  }

  const handleLessonClicked = contentId => {
    if (openLessons.includes(contentId)) {
      const updatedOpenLessons = [...openLessons].filter(elem => elem !== contentId)
      setOpenLessons(updatedOpenLessons)
    } else {
      const updatedOpenLessons = [...openLessons]
      updatedOpenLessons.push(contentId)

      setOpenLessons(updatedOpenLessons)
    }
  }

  const handleTopicClicked = contentId => {
    if (openTopics.includes(contentId)) {
      const updatedOpenTopics = [...openTopics].filter(elem => elem !== contentId)
      setOpenTopics(updatedOpenTopics)
    } else {
      const updatedOpenTopics = [...openTopics]
      updatedOpenTopics.push(contentId)
      setOpenTopics(updatedOpenTopics)
    }
  }

  useEffect(() => {
    const openLE = courseTree.children.find(lesson => isInPath(lesson.id))
    const openTO = openLE?.children.find(lesson => isInPath(lesson.id))
    if (openLE && !openLessons.includes(openLE.id)) {
      const updatedOpenLessons = [...openLessons]
      updatedOpenLessons.push(openLE.id)
      setOpenLessons(updatedOpenLessons)
    }

    if (openTO && openTO.children.length > 0 && !openTopics.includes(openTO.id)) {
      const updatedOpenTopics = [...openTopics]
      updatedOpenTopics.push(openTO.id)
      setOpenTopics(updatedOpenTopics)
    }
  }, [courseTree])

  return (
    <div
      className={
        isMobileView
          ? c.CourseOverview_Maincontainer__withoutMargin
          : c.CourseOverview_Maincontainer
      }
    >
      <CardActionArea
        onClick={() => closeCallback()}
        className={c.CourseOverview_HeaderShape}
      >
        <Grid
          container
          className={c.CourseOverview_Header}
          direction='row'
          alignItems='center'
        >
          <MenuIcon className={c.CourseOverview_Icon}></MenuIcon>
          <Typography
            style={{
              padding: theme.spacing(1),
              fontSize: 20,
              fontWeight: 500,
              color: 'white',
            }}
          >
            <FormattedMessage id={'shared.courseoverview'}></FormattedMessage>
          </Typography>
        </Grid>
      </CardActionArea>
      <Paper square style={{ backgroundColor: 'white' }}>
        <CardActionArea onClick={() => handleNavigation(courseTree)}>
          <Typography className={c.CourseOverview_Text}>{title}</Typography>
        </CardActionArea>
        {children.map((lesson, index) => (
          <div key={`lesson-${index}`} className={clsx(c.CourseOverview_MainInsetBlock)}>
            <Grid container direction='row' justifyContent='center'>
              <Seperator className={c.CourseOverview_Seperator} width='90'></Seperator>
            </Grid>
            <ContentEntryRow
              content={lesson}
              contentDone={lesson.progressData.contentDone}
              contentTotal={
                lesson.progressData.amountTopics + lesson.progressData.amountTests
              }
              progress={lesson.progress}
              active={openLessons.includes(lesson.id)}
              spotlight={currentContent.id === lesson.id}
              onClickHandler={() => {
                handleNavigation(lesson)
                if (!openLessons.includes(lesson.id)) {
                  handleLessonClicked(lesson.id)
                }
              }}
              onExpandHandler={() => handleLessonClicked(lesson.id)}
            ></ContentEntryRow>
            {openLessons.includes(lesson.id) ? (
              lesson.children.map((content, index) => {
                return (
                  <div className={c.CourseOverview_InsetBlock} key={`content-${index}`}>
                    {index !== 0 && (
                      <Grid container direction='row' justifyContent='center'>
                        <Seperator
                          className={c.CourseOverview_Seperator}
                          width='80'
                        ></Seperator>
                      </Grid>
                    )}
                    <ContentEntryRow
                      content={content}
                      contentDone={content.progressData.contentDone}
                      contentTotal={
                        content.progressData.amountTopics +
                        content.progressData.amountTests
                      }
                      progress={content.progress}
                      active={openTopics.includes(content.id) && isInPath(content.id)}
                      spotlight={currentContent.id === content.id}
                      onClickHandler={() => {
                        handleNavigation(content)
                        if (!openTopics.includes(content.id)) {
                          handleTopicClicked(content.id)
                        }
                      }}
                      onExpandHandler={() => handleTopicClicked(content.id)}
                    ></ContentEntryRow>
                    <div className={c.CourseOverview_LeafContainer}>
                      {openTopics.includes(content.id) ? (
                        content.children.map((subContent, index) => {
                          return (
                            <div
                              className={clsx(
                                c.CourseOverview_InsetBlock,
                                c.CourseOverview_LeafInsetBlock
                              )}
                              key={`subContent-${index}`}
                            >
                              <ContentEntryRow
                                content={subContent}
                                contentDone={subContent.progressData.contentDone}
                                contentTotal={
                                  subContent.progressData.amountTopics +
                                  subContent.progressData.amountTests
                                }
                                progress={subContent.progress}
                                active={isInPath(subContent.id)}
                                spotlight={currentContent.id === subContent.id}
                                onClickHandler={() => handleNavigation(subContent)}
                              ></ContentEntryRow>
                            </div>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <></>
            )}
          </div>
        ))}
      </Paper>
    </div>
  )
}

export default CourseOverview
