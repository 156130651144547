import { ClickAwayListener, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/CloseRounded'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'
import {
  getPremiumOffer,
  selectPremiumOffer,
  selectPremiumOfferStatus,
} from '@services/premiumOffer/slice'
import { STATUSES } from '@constants/slices'
import PlakosLoading from '@components/atoms/PlakosLoading'

const useStyles = makeStyles(theme => ({
  ShopMenu_Container: {
    backgroundColor: theme.palette.background.colored,
    height: 'calc(100%)',
  },
  ShopMenu_Container_Desktop: {
    height: `calc(100% - ${theme.spacing(2)})`,
    marginBottom: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  ShopMenu_CloseIcon: {
    fontSize: '32px',
    color: theme.palette.primary.main,
  },
  ShopMenu_Button: {
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '70%',
    margin: `${theme.spacing(1)} auto`,
    cursor: 'pointer',
  },
  Shopmenu_Button__Active: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  ShopMenu_ButtonText: {
    fontSize: '21px',
  },
  ShopMenu_SubHeading: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    paddingBottom: theme.spacing(2),
  },
  ShopMenu_Seperator: {
    width: '90%',
    margin: `${theme.spacing(4)} auto`,
    height: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  ShopMenu_ProductBox: {
    maxWidth: 'min(90%, 560px)',
    flex: '100px 1 0',
  },
  ShopMenu_ProductBoxContainer_Mobile: {
    flex: '100px 1 0',
  },
  ShopMenu_ProductBox_Mobile: {
    flex: '100px 1 0',
    width: 'calc(80%)',
    overflow: 'scroll',
  },
}))

const Seperator = () => {
  const c = useStyles()
  return <div className={c.ShopMenu_Seperator}></div>
}

const ShopMenu = ({
  switchCourses = null,
  freeSelected,
  closeCallback = null,
  isMobile = false,
}) => {
  const c = useStyles()
  const dispatch = useDispatch()
  const offerStatus = useSelector(selectPremiumOfferStatus)
  const offer = useSelector(selectPremiumOffer)

  useEffect(() => {
    if (offerStatus !== STATUSES.LOADED) {
      dispatch(getPremiumOffer())
    }
  }, [])

  const menu = (
    <Grid
      className={clsx(
        c.ShopMenu_Container,
        !isMobile ? c.ShopMenu_Container_Desktop : null
      )}
      container
      direction='column'
      align='center'
      justifyContent='flex-start'
    >
      {closeCallback && (
        <Grid container item justifyContent='flex-end'>
          <CloseIcon className={c.ShopMenu_CloseIcon} onClick={closeCallback}></CloseIcon>
        </Grid>
      )}
      <Grid
        item
        className={clsx(
          c.ShopMenu_Button,
          freeSelected ? c.Shopmenu_Button__Active : null
        )}
        onClick={() => switchCourses(true)}
      >
        <Typography className={c.ShopMenu_ButtonText}>
          <FormattedMessage id='shop.freeCourses'></FormattedMessage>
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          c.ShopMenu_Button,
          !freeSelected ? c.Shopmenu_Button__Active : null
        )}
        onClick={() => switchCourses(false)}
      >
        <Typography className={c.ShopMenu_ButtonText}>
          <FormattedMessage id='shop.paidCourses'></FormattedMessage>
        </Typography>
      </Grid>
      <Seperator></Seperator>
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        className={clsx(c.ShopMenu_ProductBoxContainer_Mobile)}
      >
        <Typography className={c.ShopMenu_SubHeading}>
          <FormattedMessage id='shop.recommended'></FormattedMessage>
        </Typography>
        {offerStatus === STATUSES.LOADED ? (
          <div
            className={clsx(
              'plakos-premium-offer-container',
              isMobile ? c.ShopMenu_ProductBox_Mobile : c.ShopMenu_ProductBox
            )}
            dangerouslySetInnerHTML={{ __html: offer.content.rendered }}
          ></div>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )

  if (isMobile) {
    return <ClickAwayListener onClickAway={closeCallback}>{menu}</ClickAwayListener>
  } else {
    return menu
  }
}

export default ShopMenu
