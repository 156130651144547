import { CardActionArea, Grid, Icon, Typography, withTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'
import clsx from 'clsx'
import SubjectIcon from '@mui/icons-material/SubjectRounded'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { ReactComponent as TestIcon } from '@assets/test_icon.svg'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  ContentEntryRow_MainContainer: {
    paddingRight: theme.spacing(1),
  },
  ContentEntryRow_Inactive: {
    opacity: 0.75,
  },
  ContentEntryRow_Text: {
    fontSize: '20px',
    fontWeight: '500',
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
    },
  },
  ContentEntryRow_WhiteText: {
    color: 'white',
  },
  ContentEntryRow_Spotlight: {
    backgroundColor: theme.palette.secondary.variant2,
    borderRadius: '16px',
  },
  ContentEntryRow_CurrentlyActive: {
    color: theme.palette.primary.main,
  },
  ContentEntryRow_CurrentlyInActive: {
    color: theme.palette.secondary.gray,
  },
  ContentEntryRow_Icon: {
    fontSize: '36px',
    height: '36px',
    width: '36px',
    marginRight: '4px',
  },
  ContentEntryRow_TopicRow: {
    padding: theme.spacing(1),
    flexWrap: 'nowrap',
  },
  ContentEntryRow_SubRow: {
    fontSize: '16px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '12px',
    },
  },
}))

const ContentEntryRow = ({
  content,
  contentDone = 0,
  contentTotal = 0,
  icon,
  active,
  progress,
  spotlight,
  onClickHandler,
  onExpandHandler,
}) => {
  const c = useStyles()
  const intl = useIntl()
  const { type, mediaInfo, title, children } = content
  const isExpandable = children && children.length > 0
  const getIcon = () => {
    if (type === 'test') {
      return (
        <Icon
          className={clsx(
            c.ContentEntryRow_Icon,
            spotlight
              ? c.ContentEntryRow_WhiteText
              : active
                ? c.ContentEntryRow_CurrentlyActive
              : c.ContentEntryRow_CurrentlyInActive
          )}
        >
          <TestIcon
            className={clsx(
              c.ContentEntryRow_Icon,
              spotlight
                ? c.ContentEntryRow_WhiteText
                : active
                  ? c.ContentEntryRow_CurrentlyActive
                : c.ContentEntryRow_CurrentlyInActive
            )}
          />
        </Icon>
      )
    } else if (mediaInfo.hasVideo) {
      return (
        <PlayCircleOutlineIcon
          className={clsx(
            c.ContentEntryRow_Icon,
            spotlight
              ? c.ContentEntryRow_WhiteText
              : active
              ? c.ContentEntryRow_CurrentlyActive
              : c.ContentEntryRow_CurrentlyInActive
          )}
        ></PlayCircleOutlineIcon>
      )
    } else {
      return (
        <SubjectIcon
          className={clsx(
            c.ContentEntryRow_Icon,
            spotlight
              ? c.ContentEntryRow_WhiteText
              : active
                ? c.ContentEntryRow_CurrentlyActive
              : c.ContentEntryRow_CurrentlyInActive
          )}
        ></SubjectIcon>
      )
    }
  }

  const sumUpTime = current => {
    let maxTime =
      current.estimatedTime + (current.type === 'test' ? current.timeLimit : 0)
    current.children.forEach(child => {
      maxTime += sumUpTime(child)
    })
    return maxTime
  }

  const parseEstimatedTime = () => {
    let maxTime = sumUpTime(content)

    let timeInMinutes = maxTime / 60
    const hours = timeInMinutes / 60
    const minutes = timeInMinutes % 60
    let timeString = ''
    if (Math.floor(hours) > 0)
      timeString += `${Math.floor(hours)} ${
        Math.floor(hours) > 1 ? ' Stunden' : ' Stunde'
      } `
    if (Math.floor(minutes) > 0) timeString += `${Math.floor(minutes)} Min.`
    if (timeString === '' && maxTime > 0) {
      return '1 Min.'
    }
    return timeString
  }

  const buildSubRowText = () => {
    let text = ''
    if (isExpandable) {
      text += intl.formatMessage(
        {
          id: 'shared.countOfContentDone',
        },
        {
          contentDone: contentDone || 0,
          contentTotal: contentTotal,
        }
      )
    }
    if (content.estimatedTime || content.children.length > 0) {
      if (text !== '') {
        text += ' - '
      }
      text += parseEstimatedTime()
    }
    return text
  }
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      style={{ flexWrap: 'nowrap' }}
      className={clsx(
        c.ContentEntryRow_MainContainer,
        spotlight ? c.ContentEntryRow_Spotlight : null
      )}
    >
      <CardActionArea onClick={onClickHandler}>
        <Grid
          container
          item
          direction='row'
          alignItems='center'
          className={clsx(
            c.ContentEntryRow_TopicRow,
            spotlight
              ? c.ContentEntryRow_WhiteText
              : progress === 1
              ? null
              : c.ContentEntryRow_Inactive
          )}
        >
          {content.type !== 'lesson' && <Grid item>{getIcon()}</Grid>}
          <Grid item container direction='column'>
            <Typography
              className={clsx(
                c.ContentEntryRow_Text,
                spotlight
                  ? c.ContentEntryRow_WhiteText
                  : active
                    ? c.ContentEntryRow_CurrentlyActive
                  : c.ContentEntryRow_CurrentlyInActive
              )}
            >
              {title}
            </Typography>
            {contentTotal > 0 || content?.estimatedTime > 0 ? (
              <Grid
                item
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                sm
                xs
                className={clsx(
                  spotlight
                    ? c.ContentEntryRow_WhiteText
                    : progress === 1
                      ? null
                    : c.ContentEntryRow_Inactive
                )}
              >
                <Typography
                  className={clsx(
                    c.ContentEntryRow_Text,
                    c.ContentEntryRow_SubRow,
                    spotlight
                      ? c.ContentEntryRow_WhiteText
                      : active
                      ? c.ContentEntryRow_CurrentlyActive
                      : c.ContentEntryRow_CurrentlyInActive
                  )}
                >
                  {buildSubRowText()}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
      {isExpandable ? (
        <Grid item>
          <CardActionArea onClick={onExpandHandler} disableRipple>
            {active ? (
              <ArrowDropUpIcon
                className={clsx(
                  c.ContentEntryRow_Icon,
                  spotlight
                    ? c.ContentEntryRow_WhiteText
                    : active
                      ? c.ContentEntryRow_CurrentlyActive
                    : c.ContentEntryRow_CurrentlyInActive
                )}
              ></ArrowDropUpIcon>
            ) : (
              <ArrowDropDownIcon
                className={clsx(
                  c.ContentEntryRow_Icon,
                  spotlight
                    ? c.ContentEntryRow_WhiteText
                    : active
                      ? c.ContentEntryRow_CurrentlyActive
                    : c.ContentEntryRow_CurrentlyInActive
                )}
              ></ArrowDropDownIcon>
            )}
          </CardActionArea>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default ContentEntryRow
