import React, { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { makeStyles } from '@mui/styles'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

const useStyles = makeStyles(theme => {
  return {
    FormLine: {
      margin: theme.spacing(1),
      [theme.breakpoints.down(700)]: {
        margin: '0',
      },
    },
    FormLine_Select: {
      width: '100%',
      fontSize: '30px',
    },
    FormLine_TextField: {
      width: '100%',
    },
    FormLine_DateField: {
      margin: '0',
    },
    FontLine_TextField__Font: {
      fontSize: '20px',
      lineHeight: '26px',
      [theme.breakpoints.down(1300)]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    FormLine_Title: {
      fontSize: '24px',
      lineHeight: '32px',
      [theme.breakpoints.down(1300)]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    FormLine_MenuItem: {
      fontSize: '20px',
      fontHeight: '26px',
    },
    FormLine_Title__OneLine: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }
})

const FormLine = ({ title, data = false, placeholder = '', ...props }) => {
  const c = useStyles()
  const [dateErrorPresent, setDateErrorPresent] = useState('')
  return (
    <Grid
      className={c.FormLine}
      container
      item
      direction='row'
      alignItems='center'
      wrap='wrap'
    >
      <Grid item md={1}></Grid>
      <Grid item xs={6} md={5}>
        <Typography className={clsx(c.FormLine_Title, c.FormLine_Title__OneLine)}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6} md={5}>
        {data ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={clsx(c.FormLine_TextField, c.FormLine_DateField)}
              renderInput={params => (
                <TextField
                  label='Date'
                  variant='standard'
                  helperText={
                    dateErrorPresent === 'invalidDate'
                      ? 'Bitte überprüfe deine Eingabe'
                      : null
                  }
                  {...params}
                />
              )}
              inputFormat='dd/MM/yyyy'
              onError={newError => {
                setDateErrorPresent(newError)
              }}
              margin='normal'
              InputProps={{
                classes: {
                  input: c.FontLine_TextField__Font,
                },
              }}
              {...props}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            className={c.FormLine_TextField}
            placeholder={placeholder}
            InputProps={{
              classes: {
                input: c.FontLine_TextField__Font,
              },
            }}
            {...props}
          />
        )}
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  )
}

export default FormLine
