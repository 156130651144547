import React from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  Header_IconButton: {
    padding: '17px',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(430)]: {
      padding: '17px 12px',
    },
  },
  Header_IconButton__Active: {
    color: theme.palette.primary.mainTransparent,
  },
  Header_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}))

const HeaderIconEntry = ({ children, tooltip, callback, activ }) => {
  const c = useStyles()

  return <>
    <Tooltip title={tooltip} classes={{ tooltip: c.Header_TextTooltip }}>
      <Grid container style={{ width: 'auto' }} direction='row' alignItems='center'>
        <IconButton
          className={clsx(c.Header_IconButton, activ && c.Header_IconButton__Active)}
          onClick={() => (callback ? callback() : null)}
          size="large">
          {children}
        </IconButton>
      </Grid>
    </Tooltip>
  </>;
}

export default HeaderIconEntry
