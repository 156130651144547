import React from 'react'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

const myStyles = makeStyles(() => ({
  Community: {},
}))

const Community = () => {
  const c = myStyles()

  return (
    <Box className={c.Community}>
      <h1>Community</h1>
    </Box>
  )
}

export default Community
