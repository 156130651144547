import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './app/App'
import store from './app/store'
import ContextProvider from './app/context/ContextProvider'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { StyledEngineProvider } from '@mui/material/styles'
import { getLocale, getMessages } from '@services/intl'

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <ContextProvider>
          <BrowserRouter>
            <IntlProvider locale={getLocale()} messages={getMessages()}>
              <App />
            </IntlProvider>
          </BrowserRouter>
        </ContextProvider>
      </Provider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.querySelector('#root')
)
