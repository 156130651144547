import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const STORAGE_KEY_DASHBOARD = 'plakos-courses'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    value: {},
    status: STATUSES.INIT,
  },
  reducers: {
    loaded: (_, { payload }) => ({ value: payload, status: STATUSES.LOADED }),
    loadedFromCache: (state, action) => ({...state, value: action.payload }),
    loading: state => ({...state, status: STATUSES.LOADING }),
    error: state => ({...state, status: STATUSES.ERROR }),
  },
})

export const getDashboard = () => dispatch => {
  dispatch(loading())
  try {
    let dashboardFromStorage = window.localStorage.getItem(STORAGE_KEY_DASHBOARD)
    if (dashboardFromStorage) {
      let parsed
      try {
        parsed = JSON.parse(dashboardFromStorage)
      } catch {
        window.localStorage.removeItem(STORAGE_KEY_DASHBOARD)
      }
      if (parsed) dispatch(loadedFromCache(JSON.parse(dashboardFromStorage)))
    }
  } catch {
    window.localStorage.removeItem(STORAGE_KEY_DASHBOARD)
  }
  api({ endpoint: '/user/courses', params: { includeDefault: true } })
    .then(res => {
      window.localStorage.setItem(STORAGE_KEY_DASHBOARD, JSON.stringify(res.data))
      dispatch(loaded(res.data))
    })
    .catch(e => {
      if (!(e.name && e.name === 'CanceledError')) {
        dispatch(error())
      }
    })
}

export const getDashboardSilent = () => (dispatch, getState) => {
  api({ endpoint: '/user/courses', params: { includeDefault: true } })
    .then(res => {
      dispatch(loaded(res.data))
    })
    .catch(e => {
      if (!(e.name && e.name === 'CanceledError')) {
        dispatch(error())
      }
    })
}

export const syncDashboard = (courseId, progress) => (dispatch, getState) => {
  const dashboard = getState().dashboard.value
  if (dashboard && dashboard.courses) {
    let index = dashboard.courses.findIndex(c => c.id === courseId)
    if (index) {
      let node = dashboard.courses[index]

      node = JSON.parse(JSON.stringify(node))
      node.progress = progress

      let updatedDashboard = JSON.parse(JSON.stringify(dashboard))
      updatedDashboard.courses[index] = node
      window.localStorage.setItem(STORAGE_KEY_DASHBOARD, JSON.stringify(updatedDashboard))
      dispatch(loaded(updatedDashboard))
    }
  }
}

export const selectDashboard = state => state.dashboard.value
export const selectDashboardStatus = state => state.dashboard.status

export const { loaded, loadedFromCache, loading, error } = dashboardSlice.actions
export default dashboardSlice.reducer