import {
  Backdrop,
  ClickAwayListener,
  Grid,
  Paper,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboard,
  getDashboardSilent,
  selectDashboard,
  selectDashboardStatus,
} from '@pages/Dashboard/slice'
import ShopCardCourses from '@components/molecules/Shop/ShopCardCourses'
import { useEffect } from 'react'
import ShopMenu from '@components/molecules/Shop/ShopMenu'
import { FormattedMessage } from 'react-intl'
import MenuOpenIcon from '@mui/icons-material/MenuOpenRounded'
import ShopCourseCard from '@components/molecules/Shop/ShopCourseCard'
import {
  getProfile,
  selectProfile,
  selectUserCoins,
  updateUserCoins,
} from '@pages/Profile/slice'
import PurchaseOverlay from '@components/molecules/Shop/PurchaseOverlay'
import { useHistory } from 'react-router-dom'
import { buyCourseWithCoins, selectShop, updateFreeCourseEnrollment } from './slice'
import { STATUSES } from '@constants/slices'
import { PAGE_TITLE_TYPES, setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  Shop_MainC: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  container: {
    margin: 'auto',
    height: '100%',
    width: '100%',
  },
  Shop_MainTitle: {
    fontSize: '48px',
    lineHeight: '63px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0px`,
    [theme.breakpoints.down(1280)]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down(960)]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down(550)]: {
      fontSize: '32px',
      lineHeight: '48px',
    },
  },
  MobileShopMenu_Button: {
    position: 'fixed',
    left: 0,
    top: 'calc(50vh - 48px)',
    height: 96,
    width: 48,
    backgroundColor: theme.palette.background.colored,
    borderBottomRightRadius: '50%',
    borderTopRightRadius: '50%',
  },
  MobileShopMenu_ButtonIcon: {
    fontSize: '32px',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  MobileShopMenu_Wrapper_Base: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    width: 'min(90%, 700px)',
    zIndex: 100,
    height: 'calc(100vh)',
    margin: `0px 0px -16px -${theme.spacing(2)}`,
    borderBottom: `16px solid ${theme.palette.background.colored}`,
    [theme.breakpoints.down('960')]: {
      margin: `0px 0px -8px -${theme.spacing(1)}`,
      borderBottom: `8px solid ${theme.palette.background.colored}`,
    },
  },

  MobileShopMenu_Wrapper__Hidden: {
    width: 0,
  },
}))

const MenuCloseButton = ({ clickHandler }) => {
  const c = useStyles()
  return (
    <Grid
      container
      justifyContent='flex-start'
      alignItems='center'
      className={clsx(c.MobileShopMenu_Button)}
      onClick={clickHandler}
    >
      <MenuOpenIcon className={c.MobileShopMenu_ButtonIcon}></MenuOpenIcon>
    </Grid>
  )
}

const Shop = ({ openWithCourse }) => {
  const c = useStyles()
  const dispatch = useDispatch()
  const dashboard = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)
  const userCoins = useSelector(selectUserCoins)
  const shop = useSelector(selectShop)
  const history = useHistory()
  const [showFree, setShowFree] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOverlayOpen, setIsOverlayOpen] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const { status: shopStatus } = shop
  const { id: boughtCourseId, userCoins: userCoinsAfterPurchase } = shop.value

  useEffect(() => {
    setPageTitle(PAGE_TITLE_TYPES.SHOP)
  }, [])

  useEffect(() => {
    if (dashboardStatus !== STATUSES.LOADED) {
      dispatch(getDashboard())
    }
    dispatch(getProfile())
  }, [])

  useEffect(() => {
    if (!!isOverlayOpen && boughtCourseId === isOverlayOpen.id) {
      dispatch(updateUserCoins(userCoinsAfterPurchase))
      dispatch(getDashboardSilent())
    }
  }, [shopStatus, boughtCourseId])

  useEffect(() => {
    if (openWithCourse && dashboardStatus === STATUSES.LOADED) {
      const courseToBuy = dashboard.courses.find(item => item.id == openWithCourse)
      setIsOverlayOpen(courseToBuy)
    }
  }, [dashboardStatus, openWithCourse])

  const freeCourses = dashboard?.courses?.filter(c => c.courseIsFree)
  const paidCourses = dashboard?.courses?.filter(
    c => !c.userIsEnrolled && !c.courseIsFree && c.pricing && c.pricing.regularPrice > 0
  )

  const getStartPage = () => {
    if (!openWithCourse || dashboardStatus !== STATUSES.LOADED) return 0
    else {
      const courseIndex = dashboard?.courses
        .filter(
          c =>
            !c.userIsEnrolled &&
            !c.courseIsFree &&
            c.pricing &&
            c.pricing.regularPrice > 0
        )
        .findIndex(item => item.id == openWithCourse)
      if (!courseIndex) return 0
      let res = parseInt(courseIndex / 10, 10)
      return res
    }
  }

  //switching courses that will be display. True for free Courses otherwise paid
  const switchCourses = toFree => {
    setShowFree(toFree)
  }

  const openOverlay = course => {
    setIsOverlayOpen(course)
  }

  const navToCourse = course => {
    history.push(`/course/${course.id}`)
  }

  const buyCourse = id => {
    dispatch(buyCourseWithCoins(id))
  }

  return (
    <>
      <PurchaseOverlay
        open={isOverlayOpen !== null}
        course={isOverlayOpen}
        closeHandler={() => {
          setIsOverlayOpen(null)
          if (openWithCourse) history.replace('/shop')
        }}
        navHandler={() => navToCourse(isOverlayOpen)}
        userCoins={userCoins}
        submitHandler={buyCourse}
        wasSuccessfull={boughtCourseId === isOverlayOpen?.id && isOverlayOpen !== null}
        error={shopStatus === STATUSES.ERROR}
        loading={shopStatus === STATUSES.LOADING}
      ></PurchaseOverlay>
      <Grid container direction='column' className={clsx(c.Shop_MainC)}>
        <Grid item>
          <Typography className={c.Shop_MainTitle}>
            <FormattedMessage id='shop.maintitle'></FormattedMessage>
          </Typography>
        </Grid>
        <Grid container item direction='row'>
          {!isMobile && (
            <Grid item md={3}>
              <ShopMenu switchCourses={switchCourses} freeSelected={showFree}></ShopMenu>
            </Grid>
          )}
          <Grid item md={!isMobile ? 9 : 12} sm={12} xs={12}>
            <ShopCardCourses
              courses={showFree ? freeCourses : paidCourses}
              freeSelected={showFree}
              status={dashboardStatus}
              itemCard={ShopCourseCard}
              userCoins={userCoins}
              clickHandler={showFree ? null : openOverlay}
              subClickHandler={showFree ? navToCourse : openOverlay}
              startOnPage={getStartPage()}
            ></ShopCardCourses>
          </Grid>
        </Grid>
      </Grid>
      {isMobile ? (
        <>
          <Slide direction='right' in={isMenuOpen} mountOnEnter unmountOnExit>
            <Grid className={clsx(c.MobileShopMenu_Wrapper_Base)}>
              <ShopMenu
                switchCourses={switchCourses}
                freeSelected={showFree}
                closeCallback={() => {
                  setIsMenuOpen(false)
                }}
                isMobile={true}
              ></ShopMenu>
            </Grid>
          </Slide>

          {!isMenuOpen && (
            <MenuCloseButton
              clickHandler={() => setIsMenuOpen(!isMenuOpen)}
            ></MenuCloseButton>
          )}
        </>
      ) : null}
    </>
  )
}

export default Shop
