import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  ListDivider_line: {
    flex: '1 0 calc(100% - 8px)',
    height: 2,
    backgroundColor: theme.palette.primary.main,
    margin: '8px 4px 0px 4px',
  },
  ListDivider_textcontainer: {
    minHeight: 24,
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    top: '-12px',
    left: '24px',
    padding: '0px 8px',
    backgroundColor: 'white',
  },
  ListDivider_text: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}))

const ListDivider = ({ text }) => {
  const c = useStyles()
  return (
    <div className={c.ListDivider_line}>
      <div className={c.ListDivider_textcontainer}>
        <Typography className={c.ListDivider_text}>{text}</Typography>
      </div>
    </div>
  )
}

export default ListDivider
