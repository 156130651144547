import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import { useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import {
  getCourseContent,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
} from '@services/courseOverview/slice'
import { setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  LeftSideCourse: {},
  LeftSideCourse_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    marginBottom: '19px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '11px',
    },
  },
  RightSideCourse_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
}))

const LeftSideCourse = ({ course }) => {
  const intl = useIntl()

  return (
    <>
      <SideHeader
        courseId={course.id}
        content={course}
        type={intl.formatMessage({ id: 'shared.course' })}
      />
    </>
  )
}

const Course = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const c = useStyles()
  const history = useHistory()
  const courseStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const currentContent = useSelector(selectCurrentContent)
  const [checked, setChecked] = useState(false)
  const computedStatus =
    courseTree && courseId == courseTree.id ? courseStatus : STATUSES.LOADING

  useEffect(() => {
    dispatch(getCourseContent(courseId, courseId))
  }, [dispatch, courseId])

  useEffect(() => {
    if (!courseTree || !currentContent || currentContent.id != courseId) {
      setChecked(false)
      return
    }
    if (
      STATUSES.LOADED === courseStatus &&
      courseTree.id == courseId &&
      !courseTree.introduction &&
      courseTree.children.length > 0
    ) {
      history.replace(`/lesson/${courseTree.id}/${courseTree.children[0].id}`)
    } else if (!checked) {
      setPageTitle(courseTree.type, courseTree.title)
      setChecked(true)
    }
  }, [courseTree, courseStatus, currentContent])

  return (
    <LayoutWithoutImage
      status={checked ? computedStatus : STATUSES.LOADING}
      courseTree={courseTree}
      currentContent={courseTree}
    >
      <LeftSideCourse course={courseTree} />
    </LayoutWithoutImage>
  )
}

export default Course
