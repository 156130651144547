import { Grid, IconButton, Tooltip } from '@mui/material'
import { getDashboard, selectDashboardStatus } from '@pages/Dashboard/slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { STATUSES } from '@constants/slices'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  DashboardReloadButton: {},
  DashboardReloadButton_container: {},
  DashboardReloadButton_icon: {
    fontSize: 36,
  },
  DashboardReloadButton_errorIcon: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  DashboardReloadButton_loading: {
    animationName: 'spin',
    animationTimingFunction: 'linear',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
}))

export default function DashboardReloadButton() {
  const dispatch = useDispatch()
  const c = useStyles()
  const intl = useIntl()
  const dashboardStatus = useSelector(selectDashboardStatus)

  return (
    <Grid
      item
      container
      className={c.DashboardReloadButton_container}
      justifyContent='flex-end'
      alignItems='center'
    >
      {dashboardStatus === STATUSES.ERROR && (
        <Tooltip title={intl.formatMessage({ id: 'login.serverUnreachable' })}>
          <WarningAmberIcon
            className={c.DashboardReloadButton_errorIcon}
          ></WarningAmberIcon>
        </Tooltip>
      )}
      <Tooltip title={intl.formatMessage({ id: 'shared.dashboardReload' })}>
        <IconButton
          aria-label='reload'
          color='primary'
          disabled={dashboardStatus === STATUSES.LOADING}
          className={c.DashboardReloadButton}
          size='medium'
          onClick={() => dispatch(getDashboard())}
        >
          <CachedIcon
            className={clsx(
              c.DashboardReloadButton_icon,
              dashboardStatus === STATUSES.LOADING
                ? c.DashboardReloadButton_loading
                : null
            )}
          ></CachedIcon>
        </IconButton>
      </Tooltip>
    </Grid>
  )
}
