import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import IframeResizer from 'iframe-resizer-react'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import clsx from 'clsx'
import { minWidth } from '@mui/system'
import PlakosLoading from '@components/atoms/PlakosLoading'

const FALLBACK_TIMEOUT = 60000
const ANIMATION_TIME = 750

const useStyles = makeStyles(theme => ({
  FENIntegration_PracticeMode: {
    border: `5px solid ${theme.palette.practiceMode.primary}`,
  },
  FENIntegration_TestMode: {
    border: `5px solid ${theme.palette.content.accent}`,
  },
  FENIntegration_Init: {
    border: `5px solid ${theme.palette.content.accent}`,
  },
  FENIntegration_Animation: {
    animation: `iFrameAnimation ${ANIMATION_TIME}ms ease-in-out`,
  },
  Iframe: {
    minWidth: '100%',
    display: 'block',
  },
  Iframe__Loading: {
    display: 'none',
  },
  IframeC: {
    position: 'relative',
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  LoadingFrame: {
    backgroundColor: 'white',
    minHeight: '100px',
    border: `5px solid ${theme.palette.content.accent}`,
  },
  FENOverlay_base: {
    position: 'absolute',
    zIndex: '1000',
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
    margin: '5px',
  },
  FENOverlay_Show: {
    display: 'block',
    backgroundColor: 'white',
  },
  FENOverlay_Hide: {
    display: 'none',
    backgroundColor: 'none',
  },
  FENOverlay_Animation: {
    animation: `iFrameAnimation ${ANIMATION_TIME}ms ease-in-out`,
  },
}))

const MODES = {
  INIT: 'INIT',
  TEST: 'TEST',
  PRACTICE: 'PRACTICE',
}

const FENIntegration = ({ testLink, progress = null, updateCallback }) => {
  const styles = useStyles()
  const [updateNeeded, setUpdateNeeded] = useState(false)
  const [currentMode, setCurrentMode] = useState(MODES.INIT)
  const enableAnimation = useRef(false)
  const [animate, setAnimate] = useState(false)
  const [showOverlayDiv, setShowOverlayDiv] = useState(false)
  const updatedNeededRef = React.useRef(updateNeeded)
  const [isFenLoaded, setIsFenLoaded] = useState(false)
  const iframeRef = useRef(null)

  useEffect(() => {
    let fallbackTimeout
    setIsFenLoaded(false)
    fallbackTimeout = setTimeout(() => setIsFenLoaded(true), FALLBACK_TIMEOUT)
    return () => {
      if (fallbackTimeout) {
        clearTimeout(fallbackTimeout)
      }
    }
  }, [testLink])

  useEffect(() => {
    let timeOut
    let overlayTimeout
    if (animate) {
      timeOut = setTimeout(() => setAnimate(false), ANIMATION_TIME)
    }
    if (showOverlayDiv) {
      overlayTimeout = setTimeout(() => setShowOverlayDiv(false), ANIMATION_TIME / 2)
    }
    return () => {
      if (timeOut) clearTimeout(timeOut)
    }
  }, [animate])

  const animateTurn = () => {
    if (enableAnimation.current) {
      setShowOverlayDiv(true)
      setAnimate(true)
    } else {
      enableAnimation.current = true
    }
  }

  const onMessage = data => {
    if (data.message.includes('FEN_LOADED') || data.message.includes('RT_LOADED')) {
      setIsFenLoaded(true)
    } else if (data.message.includes('FEN_UPDATE_TRIGGERED')) {
      if (data.message.includes('--force')) {
        updateCallback(true)
      } else {
        setUpdateNeeded(true)
      }
    } else if (data.message.includes('FEN_HEIGHT_CHANGED')) {
      if (iframeRef.current)
        setTimeout(() => {
          iframeRef.current.resize()
        }, 3000)
    } else if (data.message.includes('FEN_SWITCH')) {
      if (data.message.includes('FEN_SWITCH_PRACTICE')) {
        animateTurn()
        setCurrentMode(MODES.PRACTICE)
      } else if (data.message.includes('FEN_SWITCH_TEST')) {
        animateTurn()
        setCurrentMode(MODES.TEST)
      }
    }
  }

  useEffect(() => {
    updatedNeededRef.current = updateNeeded
  }, [updateNeeded])

  useEffect(() => {
    return () => {
      if (updatedNeededRef.current) {
        updateCallback()
      }
    }
  }, [testLink])

  return (
    <div className={styles.IframeC}>
      <div
        className={clsx(
          styles.FENOverlay_base,
          showOverlayDiv ? styles.FENOverlay_Show : styles.FENOverlay_Hide,
          animate ? styles.FENOverlay_Animation : null
        )}
      ></div>
      <IframeResizer
        forwardRef={iframeRef}
        heightCalculationMethod='taggedElement'
        src={`${testLink}`}
        className={clsx(
          animate ? styles.FENIntegration_Animation : null,
          isFenLoaded ? styles.Iframe : styles.Iframe__Loading,
          currentMode === MODES.PRACTICE
            ? styles.FENIntegration_PracticeMode
            : currentMode === MODES.TEST
            ? styles.FENIntegration_TestMode
            : styles.FENIntegration_Init
        )}
        onMessage={onMessage}
      />
      {!isFenLoaded && (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          className={styles.LoadingFrame}
        >
          <PlakosLoading paddingTop={8}/>
        </Grid>
      )}
    </div>
  )
}

export default FENIntegration
