import api from './api'

const ACTIVE_COURSE_STORAGE_KEY = 'apelio-memberarea-active-course'

export const setCourseTree = course => {
    sessionStorage.removeItem(ACTIVE_COURSE_STORAGE_KEY)
    sessionStorage.setItem(ACTIVE_COURSE_STORAGE_KEY, JSON.stringify(course))
}

export const getCourseTree = async id => {
    const courseData = await fetchCourseTree(id)
    return courseData.data
}

export const triggerCacheUpdate = async id => {
    const courseData = await fetchCourseTree(id)
    setCourseTree(courseData.data)
}

export const setCourseCacheManualy = tree => {
    setCourseTree(tree)
}

async function fetchCourseTree(id) {
    return await api({
        endpoint: `/getCourseTree/${id}`,
        method: 'GET',
    })
}

export const isIdInContentPath = (content, id) => {
    let current = content
    while (current) {
        if (current.id === id) return true
        else current = current.surroundingContent
    }
    return false
}

export const getCourseContent = courseId => {
    return JSON.parse(sessionStorage.getItem(ACTIVE_COURSE_STORAGE_KEY))
}

/**
 *
 * @param contentNode
 * @param contentId
 * @returns {null|{surroundingContent}|*}
 */
export const getCourseContentById = (contentNode, contentId) => {
    contentNode = JSON.parse(JSON.stringify(contentNode))
    if (typeof contentId !== 'number') {
        contentId = parseInt(contentId, 10)
    }

    if (contentNode.id === contentId) {
        return contentNode
    }

    for (let i = 0; i < contentNode.children.length; i++) {
        let result = getCourseContentById(contentNode.children[i], contentId)
        if (result) {
            if (result.surroundingContent) {
                let root = result.surroundingContent
                while (root.surroundingContent) {
                    root = root.surroundingContent
                }
                root.surroundingContent = contentNode
                return result
            } else {
                return {...result, surroundingContent: contentNode }
            }
        }
    }
    return null
}

/**
 *
 * @param contentNode
 * @returns {null|*}
 */
export const getNextContentNode = contentNode => {
    return _getNeighborContent(contentNode, 'next')
}

export const getPreviousContentNode = contentNode => {
    return _getNeighborContent(contentNode, 'previous')
}

/**
 *
 * @param contentNode
 * @param direction
 * @returns {null|*}
 */
const getNeighborContent = (contentNode, direction) => {
    if (!contentNode.surroundingContent) {
        // Indication for the last contentNode within the current course
        return null
    }
    // Finding index of current contentNode
    const contentNodeIndex = contentNode.surroundingContent.children.findIndex(
        node => node.id === contentNode.id
    )
    const nextIndex = contentNodeIndex + (direction === 'next' ? 1 : -1)
        // Checking whether the nextIndex is within bounds
    if (0 <= nextIndex && nextIndex < contentNode.surroundingContent.children.length) {
        return contentNode.surroundingContent.children[nextIndex]
    } else {
        return getNeighborContent(contentNode.surroundingContent, direction)
    }
}

/**
 *
 * @param contentNode
 * @param direction
 * @returns {null|*}
 */
const _getNeighborContent = (contentNode, direction, childIndex = -1) => {
    if (contentNode === null) {
        return null
    }

    //If child has called recusion we are navigaten to the root of the tree and see if we can find some content not in the path of the child
    if (childIndex !== -1) {
        const nextIndex = childIndex + (direction === 'next' ? 1 : -1)
        if (0 <= nextIndex && nextIndex < contentNode.children.length) {
            return contentNode.children[nextIndex]
        } else {
            if (direction !== 'next' && contentNode.introduction) {
                return contentNode
            }
            if (!contentNode.surroundingContent) {
                // Indication for the last contentNode within the current course
                return null
            }
            const contentNodeIndex = contentNode.surroundingContent.children.findIndex(
                node => node.id === contentNode.id
            )
            return _getNeighborContent(
                contentNode.surroundingContent,
                direction,
                contentNodeIndex
            )
        }
    } else {
        if (direction === 'next') {
            if (contentNode.children && contentNode.children.length > 0) {
                return contentNode.children[0]
            } else if (contentNode.surroundingContent) {
                const contentNodeIndex = contentNode.surroundingContent.children.findIndex(
                    node => node.id === contentNode.id
                )
                return _getNeighborContent(
                    contentNode.surroundingContent,
                    direction,
                    contentNodeIndex
                )
            }
        } else if (contentNode.surroundingContent) {
            const contentNodeIndex = contentNode.surroundingContent.children.findIndex(
                node => node.id === contentNode.id
            )
            return _getNeighborContent(
                contentNode.surroundingContent,
                direction,
                contentNodeIndex
            )
        }
    }
    return null
}