import locale_en from '@translations/en.json'
import locale_de from '@translations/de.json'
import { createIntl } from 'react-intl'

const DEFAULT_LOCALE = 'de'

const data = {
  de: locale_de,
  en: locale_en,
}

export const lang = navigator.language || navigator.languages

export function getLocale() {
  if (lang in data) return lang
  else if (lang.substring(0, 2) in data) return lang.substring(0, 2)
  else return DEFAULT_LOCALE
}

export function getMessages() {
  return data[getLocale()]
}

export function getIntl() {
  return createIntl({
    locale: getLocale(),
    messages: getMessages(),
  })
}
