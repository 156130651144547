import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import { useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import {
  getCourseContent,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
} from '@services/courseOverview/slice'
import { setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  Lesson: {},
  Lesson_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  Lesson_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Lesson_CountOfContent: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: theme.spacing(2),
    },
  },
  Lesson_CardsRow: {
    marginTop: theme.spacing(4),
  },
}))

const LeftSideLesson = ({ lesson, courseId }) => {
  const intl = useIntl()
  const c = useStyles()
  const {
    title,
    introduction,
    children = [],
    amountTopics = 0,
    amountTests = 0,
    surroundingContent,
    estimatedTime,
  } = lesson
  return (
    <>
      <SideHeader
        content={lesson}
        type={intl.formatMessage({ id: 'shared.lesson' })}
        courseId={courseId}
      />
    </>
  )
}

const Lesson = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, lessonId } = useParams()
  const lessonStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const lesson = useSelector(selectCurrentContent)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    dispatch(getCourseContent(courseId, lessonId))
  }, [dispatch, courseId, lessonId])

  useEffect(() => {
    if (!lesson || lesson.id != lessonId) {
      setChecked(false)
      return
    }

    if (
      STATUSES.LOADED === lessonStatus &&
      courseTree &&
      courseTree.id == courseId &&
      !lesson.introduction &&
      lesson.children?.length > 0
    ) {
      const child = lesson.children[0]
      history.replace(
        `/${child.type === 'test' ? 'test' : 'topic'}/${courseTree.id}/${child.id}`
      )
    } else if (!checked) {
      setPageTitle(lesson.type, lesson.title)
      setChecked(true)
    }
  }, [courseTree, lesson, lessonStatus])

  return (
    <LayoutWithoutImage
      status={checked ? lessonStatus : STATUSES.LOADING}
      courseTree={courseTree}
      currentContent={lesson}
    >
      <LeftSideLesson lesson={lesson} courseId={courseId}></LeftSideLesson>
    </LayoutWithoutImage>
  )
}

export default Lesson
