import PriceInCoins from '@components/atoms/PriceInCoins'
import { Backdrop, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import clsx from 'clsx'
import { COIN_SHOP_URL } from '@constants/constants'
import PlakosLoading from '@components/atoms/PlakosLoading'

const useStyles = makeStyles(theme => ({
  PurchaseOverlay_Backdrop: {
    zIndex: 1000,
  },
  PurchaseOverlay_Card: {
    padding: theme.spacing(2),
    minWidth: 'min(500px, 95vw)',
  },
  PurchaseOverlay_Seperator: {
    width: '80%',
    height: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  PurchaseOverlay_NoCoinsText: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
  PurchaseOverlay_ErrorText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 18,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  PurchaseOverlay_ButtonText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  PurchaseOverlay_Text__Disabled: {
    color: theme.palette.disabled.main,
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 0,
    height: 0,
    borderRadius: '50%',
    opacity: 0,
    transition: 'all 0.5s ease-in-out',
    margin: theme.spacing(1),
  },
  circle__animated: {
    width: 64,
    height: 64,
    opacity: 1,
    border: `2px solid ${theme.palette.primary.green}`,
  },
  icon: {
    fontSize: 0,
    transition: 'all 0.5s ease-in-out 0.2s',
    transform: 'rotate(0deg)',
  },
  icon__animated: {
    fontSize: 32,
    transform: 'rotate(720deg)',
    transition: 'all 0.5s ease-in-out 0.2s',
    color: theme.palette.primary.green,
  },
}))

const AnimatedSuccess = () => {
  const c = useStyles()
  const [animated, setAnimated] = useState(false)
  useEffect(() => {
    setTimeout(() => setAnimated(true), 100)
  }, [])
  return (
    <div className={clsx(c.circle, animated ? c.circle__animated : null)}>
      <CheckIcon className={clsx(c.icon, animated ? c.icon__animated : null)}></CheckIcon>
    </div>
  )
}

const PurchaseSummary = ({ pricing, userCoins, title }) => {
  const c = useStyles()
  return (
    <>
      <Grid item>
        <Typography align='center'>
          <FormattedMessage
            id='shop.purchaseConfirm'
            values={{
              title: (
                <strong>
                  <br></br>
                  {title}
                  <br></br>
                </strong>
              ),
            }}
          ></FormattedMessage>
        </Typography>
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Typography>
          <FormattedMessage id='shop.yourCoins'></FormattedMessage>
        </Typography>
        <Grid item>
          <PriceInCoins price={userCoins}></PriceInCoins>
        </Grid>
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Typography>
          <FormattedMessage id='shop.coursePrice'></FormattedMessage>
        </Typography>
        <Grid item>
          <PriceInCoins price={pricing?.regularPrice}></PriceInCoins>
        </Grid>
      </Grid>
      <Grid item className={c.PurchaseOverlay_Seperator}></Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Typography>
          <FormattedMessage id='shop.balance'></FormattedMessage>
        </Typography>
        <Grid item>
          <PriceInCoins price={userCoins - pricing?.regularPrice}></PriceInCoins>
        </Grid>
      </Grid>
    </>
  )
}

const STATES = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_COINS: 'NO_COINS',
}

const PurchaseOverlay = ({
  open,
  course,
  userCoins,
  closeHandler,
  wasSuccessfull = false,
  submitHandler,
  navHandler,
  error,
  loading,
}) => {
  const c = useStyles()
  const [currentState, setCurrentState] = useState(STATES.LOADING)
  const { title = '', pricing = { regularPrice: 0 }, id } = course || {}

  useEffect(() => {
    if (userCoins < pricing.regularPrice) {
      setCurrentState(STATES.NO_COINS)
    } else if (error) {
      setCurrentState(STATES.ERROR)
    } else if (wasSuccessfull) {
      setCurrentState(STATES.SUCCESS)
    } else if (loading) {
      setCurrentState(STATES.LOADING)
    } else {
      setCurrentState(STATES.LOADED)
    }
  }, [wasSuccessfull, error, course, loading])
  return (
    <Backdrop
      open={open}
      className={c.PurchaseOverlay_Backdrop}
      onClick={currentState === STATES.LOADING ? closeHandler : null}
    >
      {course && (
        <Paper className={c.PurchaseOverlay_Card} onClick={e => e.stopPropagation()}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            {currentState === STATES.ERROR && (
              <Typography className={c.PurchaseOverlay_ErrorText}>
                <FormattedMessage id='shop.purchaseFailed'></FormattedMessage>
              </Typography>
            )}
            {currentState === STATES.LOADING && <PlakosLoading></PlakosLoading>}
            {currentState === STATES.SUCCESS && <AnimatedSuccess></AnimatedSuccess>}
            {currentState === STATES.NO_COINS && (
              <Typography className={c.PurchaseOverlay_NoCoinsText}>
                <FormattedMessage
                  id='shop.notEnoughCoins'
                  values={{
                    title: <strong>{title}</strong>,
                  }}
                ></FormattedMessage>
              </Typography>
            )}
            {currentState !== STATES.ERROR &&
              currentState !== STATES.NO_COINS &&
              currentState !== STATES.SUCCESS && (
              <PurchaseSummary
               pricing={pricing}
               userCoins={userCoins}
               title={title}
             ></PurchaseSummary>
         )}
          </Grid>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ gap: '16px' }}
          >
            <Button onClick={currentState !== STATES.LOADING ? closeHandler : null}>
              <Typography className={c.PurchaseOverlay_ButtonText}>
                <FormattedMessage id={'shared.cancel'}></FormattedMessage>
              </Typography>
            </Button>
            {currentState === STATES.SUCCESS ? (
              <Button onClick={() => navHandler(id)}>
                <Typography className={c.PurchaseOverlay_ButtonText}>
                  <FormattedMessage id={'shared.toCourse'}></FormattedMessage>
                </Typography>
              </Button>
            ) : currentState === STATES.NO_COINS ? (
              <Button onClick={() => window.open(COIN_SHOP_URL, '_blank', 'noreferrer')}>
                <Typography className={c.PurchaseOverlay_ButtonText}>
                  <FormattedMessage id={'shop.buyCoins'}></FormattedMessage>
                </Typography>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (currentState !== STATES.LOADING) {
                    setCurrentState(STATES.LOADING)
                    submitHandler(id)
                  }
                }}
                disabled={currentState === STATES.LOADING}
              >
                <Typography
                  className={clsx(
                    c.PurchaseOverlay_ButtonText,
                    currentState === STATES.LOADING
                      ? c.PurchaseOverlay_Text__Disabled
                      : null
                  )}
                >
                  <FormattedMessage id={'shared.buy'}></FormattedMessage>
                </Typography>
              </Button>
            )}
          </Grid>
        </Paper>
      )}
    </Backdrop>
  )
}

export default PurchaseOverlay
