import React from 'react'
import Lottie from 'lottie-react'
import loading from '../../@assets/animations/loading.json'

// https://www.npmjs.com/package/lottie-react

const PlakosLoading = ({ size = 100, paddingTop = 0 }) => {
  return (
    <Lottie
      animationData={loading}
      style={{ width: size, margin: 'auto auto', paddingTop: paddingTop }}
    />
  )
}

export default PlakosLoading
