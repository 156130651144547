import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Link from '@mui/material/Link'
import { Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  Impressum_MainContainer: {
    padding: '16px 64px 0px 64px',
    [theme.breakpoints.down(600)]: {
      padding: '16px 16px 0px 16px',
    },
  },
  Impressum_MainHeadingContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  Impressum_MainHeading: {
    fontSize: '17pt',
    fontWeight: 'bold',
  },
  Impressum_SubHeading: {
    fontWeight: 'bold',
  },
  Impressum_InfoBlock: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  Impressum_Heading: {
    fontSize: '14pt',
    fontWeight: 'bold',
  },
  Impressum_CloseButton: {
    '&:hover': {
      background: 'none',
    },
  },
  Impressum_CloseIcon: {
    color: 'black',
    height: '32px',
    width: '32px',
  },
}))

const Impressum = () => {
  const c = useStyles()
  const history = useHistory()

  return (
    <>
      <Grid container direction='column' className={c.Impressum_MainContainer}>
        <Grid container item direction='column'>
          <Grid
            container
            item
            direction='row'
            className={c.Impressum_MainHeadingContainer}
          >
            <Typography className={c.Impressum_MainHeading}>
              Technische Umsetzung des Tools und der Lerninhalte:
            </Typography>
            <Button
              disableRipple={true}
              className={c.Impressum_CloseButton}
              onClick={() => history.goBack()}
            >
              <CloseIcon className={c.Impressum_CloseIcon}></CloseIcon>
            </Button>
          </Grid>
        </Grid>
        <Grid container item direction='column'>
          <Grid item className={c.Impressum_InfoBlock}>
            <Typography>Apelio E-Learning GmbH</Typography>
            <Typography>Willy-Brandt-Allee 31</Typography>
            <Typography>23554 Lübeck</Typography>
          </Grid>
          <Grid item className={c.Impressum_InfoBlock}>
            <Typography className={c.Impressum_SubHeading}>
              Allgemeine Anfragen:
            </Typography>
            <Typography>
              Email: <a href='mailto:info@apel.io'>info@apel.io</a>
            </Typography>
            <Typography>Tel.: +49 151 / 41624436</Typography>
          </Grid>
          <Grid item className={c.Impressum_InfoBlock}>
            <Typography className={c.Impressum_SubHeading}>
              Umsatzsteuer-Identifikationsnummer:
            </Typography>
            <Typography>DE335598634</Typography>
          </Grid>
          <Grid item className={c.Impressum_InfoBlock}>
            <Typography className={c.Impressum_SubHeading}>
              Vertretungsberechtigter Geschäftsführer:
            </Typography>
            <Typography>Julia Erdmann</Typography>
          </Grid>
          <Grid item className={c.Impressum_InfoBlock}>
            <Typography className={c.Impressum_SubHeading}>
              Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-Verordnung:
            </Typography>
            <Typography className={c.Impressum_InfoBlock}>
              Die Europäische Kommission stellt eine Plattform zur außergerichtlichen
              Online-Streitbeilegung (OS-Plattform) bereit, die Sie unter{' '}
              <Link
                href='https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=DE'
                underline='hover'
              >
                https://webgate.ec.europa.eu
              </Link>
              erreichen.
            </Typography>
            <Typography className={c.Impressum_InfoBlock}>
              Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teil.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction='column'>
          <Typography className={c.Impressum_Heading}>Disclaimer</Typography>
          <Typography className={c.Impressum_InfoBlock}>
            Wir prüfen die Informationen auf den eigenen Webseiten sehr sorgfältig und
            aktualisiert diese regelmäßig. Trotzdem können wir keine Garantie oder Haftung
            dafür übernehmen, dass die von uns, von unseren Usern, oder von uns
            beauftragten Dienstleistern bereitgestellten Informationen vollständig,
            richtig und in jedem Fall aktuell sind. Alle Informationen dienen
            ausschließlich zur Information der Besucher.
          </Typography>
          <Typography className={c.Impressum_InfoBlock}>
            Wichtig! Keine Abmahnung ohne vorherigen Kontakt! Sollte der Inhalt oder die
            Aufmachung dieser Seiten fremde Rechte Dritter, oder gesetzliche Bestimmungen
            verletzen, so bitten wir um eine entsprechende Nachricht ohne Kostennote. Die
            Beseitigung einer möglicherweise von diesen Seiten ausgehende
            Schutzrecht-Verletzung durch Schutzrecht-InhaberInnen selbst darf nicht ohne
            Zustimmung stattfinden. Wir garantieren, dass die zu Recht beanstandeten
            Passagen unverzüglich entfernt werden, ohne dass von Ihrer Seite die
            Einschaltung eines Rechtsbeistandes erforderlich ist. Der Betreiber der
            Website kann nicht für die geposteten Inhalte von Usern der Site
            verantwortlich gemacht werden. Da jeder Besucher der Website anonym posten
            kann, ist keine eindeutige Zuordnung möglich. Es ist dem Webmaster unmöglich
            ständig die Inhalte zu überprüfen, freie Meinungsäußerung ist in der BRD im
            Grundgesetz verankert!
          </Typography>
          <Typography className={c.Impressum_InfoBlock}>
            Schutzrechtsverletzung: Falls Sie meinen, dass von dieser Website aus eines
            Ihrer Schutzrechte verletzt wird, bitten wir Sie, zur Vermeidung unnötiger
            Rechtsstreite und Kosten, uns umgehend bereits im Vorfeld zu kontaktieren,
            damit zügig Abhilfe geschaffen werden kann. Bitte nehmen Sie zur Kenntnis: Das
            zeitaufwändigere Einschalten eines Anwaltes zur Erstellung einer für den
            Diensteanbieter kostenpflichtigen Abmahnung entspricht nicht dessen wirklichen
            oder mutmaßlichen Willen. Die Kostennote einer anwaltlichen Abmahnung ohne
            vorhergehende Kontaktaufnahme mit uns wird daher im Sinne der
            Schadensminderungspflicht als unbegründet zurückgewiesen.
          </Typography>
        </Grid>
        <Grid container item direction='column'>
          <Typography className={c.Impressum_Heading}>Datenspeicherung</Typography>
          <Typography className={c.Impressum_InfoBlock}>
            Die Vorschriften des Bundesdatenschutzgesetzes und des Multimediagesetzes
            werden beachtet. Personenbezogene Daten von Benutzern werden nur erhoben,
            verarbeitet und gespeichert, soweit dies im Rahmen der in Anspruch genommenen
            Service-Leistungen erforderlich ist.
          </Typography>
          <Typography className={c.Impressum_InfoBlock}>
            Auf dieser Website können zu Marketing- und Optimierungszwecken Daten in
            anonymisierter Form gesammelt und gespeichert werden. Aus diesen Daten können
            unter einem Pseudonym Nutzungsprofile erstellt. Hierzu können Cookies
            eingesetzt werden, die allerdings Daten ausschließlich in pseudonymer Form
            sammeln und speichern. Die Daten werden nicht dazu benutzt, den Besucher
            dieser Website persönlich zu identifizieren und werden nicht mit Daten über
            den Träger des Pseudonyms zusammengeführt. Die Auswertung von
            Zugriffsstatistiken, die über die Nutzungsfrequenz bestimmter Angebote auf den
            Web-Seiten von apel.io Auskunft geben, erfolgt also in anonymisierter Form.
            Alle freiwilligen Einträge in angebotene Kommentarfelder müssen zur
            Verarbeitung und anschließenden Darstellung gespeichert werden.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Impressum
