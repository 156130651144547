import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import SideHeader from '@components/molecules/SideHeader'
import DOMPurify from 'dompurify'
import { useIntl } from 'react-intl'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import { STATUSES } from '@constants/slices'
import {
  getCourseContent,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
  updateCourseTree,
  updateCourseTreeProgress,
} from '@services/courseOverview/slice'
import FENIntegration from '@components/atoms/FENIntegration'
import { setPageTitle } from '@services/gaIntegration'

const Test = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const { courseId, testId } = useParams()
  const courseStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const test = useSelector(selectCurrentContent)
  const [checked, setChecked] = useState(false)
  const testStatus = test && testId == test.id ? STATUSES.LOADED : STATUSES.LOADING

  const testIdRef = React.useRef(testId)

  useEffect(() => {
    testIdRef.current = testId
  }, [testId])

  const { progress, testLink } = test || {}
  const updateCallback = (val = false) => {
    if (val) {
      //Progress has changed
      dispatch(updateCourseTreeProgress(courseId, testIdRef.current, 1))
    } else {
      //Notepad may have changed
      dispatch(updateCourseTree())
    }
  }

  useEffect(() => {
    if (testStatus === STATUSES.LOADING) {
      dispatch(getCourseContent(courseId, testId))
    }
  }, [dispatch, courseId, testId, testStatus])

  useEffect(() => {
    if (!test || test.id != testId) {
      setChecked(false)
      return
    }
    if (!checked) {
      setPageTitle(test.type, test.title)
      setChecked(true)
    }
  }, [courseTree, test, testStatus])

  return (
    <LayoutWithoutImage
      status={courseStatus}
      courseTree={courseTree}
      currentContent={test}
    >
      <SideHeader
        content={test}
        courseId={courseId}
        type={intl.formatMessage({ id: 'shared.test' })}
        showProgress
      />
      <FENIntegration
        testLink={testLink}
        progress={progress}
        updateCallback={updateCallback}
      ></FENIntegration>
    </LayoutWithoutImage>
  )
}

export default Test
