import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'

const useStyles = makeStyles(theme => ({
  digitCard: {
    backgroundColor: theme.palette.secondary.variant1,
    borderRadius: 4,
    width: 30,
    height: 40,
    margin: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

const CountDownDigit = ({ text }) => {
  const styles = useStyles()
  return (
    <div className={styles.digitCard}>
      <Typography className={styles.text}>{text}</Typography>
    </div>
  )
}

export default CountDownDigit
