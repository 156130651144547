import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'
import { updateCourseTree, updateCourseTreeProgress } from '@services/courseOverview/slice'

export const topicSlice = createSlice({
  name: 'topic',
  initialState: {
    value: {},
    status: STATUSES.INIT,
  },
  reducers: {
    loaded: (state, { payload }) => ({
      ...state,
      value: payload,
      status: STATUSES.LOADED,
    }),
    loading: state => ({...state, status: STATUSES.LOADING }),
    error: state => ({...state, status: STATUSES.ERROR }),
    progress: (state, action) => {
      state.value = {
        ...state.value,
        progress: action.payload,
      }
    },
  },
})

export const getTopic = (courseId, topicId) => dispatch => {
  dispatch(loading())
  api({ endpoint: `/topics/${courseId}/${topicId}` })
    .then(res => {
      dispatch(loaded(res.data))
    })
    .catch(() => dispatch(error()))
}

export const putProgress = (courseId, topicId, value) => dispatch => {
  api({
      endpoint: `/progress/${courseId}`,
      method: 'PUT',
      body: {
        id: topicId,
        progress: value,
      },
    })
    .then(res => {
      dispatch(progress(value))
      dispatch(updateCourseTreeProgress(courseId, topicId, value))
      return Promise.resolve()
    })
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export const selectTopic = state => state.topic.value
export const selectTopicStatus = state => state.topic.status

export const { loaded, loading, error, progress } = topicSlice.actions
export default topicSlice.reducer