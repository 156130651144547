import React, { useState } from 'react'
import CountDownDigit from '../atoms/CountDownDigit'
import { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Paper, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, selectProfile } from '@pages/Profile/slice'
import { STATUSES } from '@constants/slices'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  digitContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 4,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  seperatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  seperatorDot: {
    margin: '2px 2px',
    borderRadius: 2,
    height: 4,
    width: 4,
    backgroundColor: theme.palette.secondary.main,
  },
  subHeading: {
    color: theme.palette.primary.main,
    fontSize: 16,
    textAlign: 'center',
  },
  invalidData: {
    color: theme.palette.primary.main,
    fontSize: 16,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  card: {
    margin: `${theme.spacing(2)} 0px`,
    padding: 8,
    borderRadius: 8,
    backgroundColor: 'white',
    flex: 1,
    maxWidth: 300,
  },
}))

const NO_ASSESSMENT_DATE = 'NO_ASSESSMENT_DATE'
const ASSESSMENT_DATE_FAR_FUTURE = 'ASSESSMENT_DATE_FAR_FUTURE'
const ASSESSMENT_DATE_ALREADY_PASSED = 'ASSESSMENT_DATE_ALREADY_PASSED'

const defaultValue = {
  month: {
    zero: '-',
    first: '-',
  },
  days: {
    zero: '-',
    first: '-',
  },
}

const Seperator = () => {
  const styles = useStyles()
  return (
    <div className={styles.seperatorContainer}>
      <div className={styles.seperatorDot} />
      <div className={styles.seperatorDot} />
    </div>
  )
}

const AssesmentCountDown = () => {
  const dispatch = useDispatch()
  const [timeLeftPerDigit, setTimeLeftPerDigit] = useState(defaultValue)
  const { value: profile, status } = useSelector(selectProfile)
  //Enums for invalidData. Can be cause of no present assesmentDate or a Data far in the Future.
  const [invalidData, setInvalidData] = useState('')
  const styles = useStyles()
  const intl = useIntl()

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  // Calculates the digits for rendering the Countdown
  useEffect(() => {
    const splitDays = days => {
      const months = days / 30
      const daysLeft = days % 30
      setTimeLeftPerDigit({
        month: {
          zero: Math.floor(months % 10),
          first: Math.floor(months / 10),
        },
        days: {
          zero: Math.floor(daysLeft % 10),
          first: Math.floor(daysLeft / 10),
        },
      })
    }

    const calculateDigits = async () => {
      const { assessmentDate } = profile
      if (!assessmentDate) {
        setInvalidData(NO_ASSESSMENT_DATE)
      }
      const parsedDate = assessmentDate.split('.').reverse().join('-')
      const differenceDays = Math.round(
        (new Date(parsedDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
      )
      if (differenceDays < 0) {
        setInvalidData(ASSESSMENT_DATE_ALREADY_PASSED)
      }
      if (differenceDays > 365 * 2) {
        setInvalidData(ASSESSMENT_DATE_FAR_FUTURE)
      }
      splitDays(differenceDays)
    }
    if (status === STATUSES.LOADED) {
      setInvalidData('')
      calculateDigits()
    }
  }, [status])

  if (invalidData === NO_ASSESSMENT_DATE) {
    return (
      <Paper className={styles.card}>
        <Typography className={styles.invalidData}>
          <FormattedMessage
            id='assesmentdate.noAssesment'
            values={{
              profile: (
                <a href={`${document.location.origin}/profile`}>
                  {intl.formatMessage({ id: 'shared.myProfile' })}
                </a>
              ),
            }}
          ></FormattedMessage>
        </Typography>
      </Paper>
    )
  }
  if (invalidData === ASSESSMENT_DATE_FAR_FUTURE) {
    return (
      <Paper className={styles.card}>
        <Typography className={styles.invalidData}>
          <FormattedMessage id='assesmentdate.farFuture'></FormattedMessage>
        </Typography>
      </Paper>
    )
  }
  if (invalidData === ASSESSMENT_DATE_ALREADY_PASSED) {
    return (
      <Paper className={styles.card}>
        <Typography className={styles.invalidData}>
          <FormattedMessage
            id='assesmentdate.alreadyPassed'
            values={{
              profile: (
                <a href={`${document.location.origin}/profile`}>
                  {intl.formatMessage({ id: 'shared.myProfile' })}
                </a>
              ),
            }}
          ></FormattedMessage>
        </Typography>
      </Paper>
    )
  }
  return (
    <div className={styles.container}>
      <Typography className={styles.subHeading}>
        <FormattedMessage id='assesmentdate.timeLeft'></FormattedMessage>
      </Typography>
      <div className={styles.subContainer}>
        <div className={styles.digitContainer}>
          <CountDownDigit text={timeLeftPerDigit.month.first} />
          <CountDownDigit text={timeLeftPerDigit.month.zero} />
        </div>
        <Seperator />
        <div className={styles.digitContainer}>
          <CountDownDigit text={timeLeftPerDigit.days.first} />
          <CountDownDigit text={timeLeftPerDigit.days.zero} />
        </div>
      </div>
      <div className={styles.labelContainer}>
        <Typography className={styles.text} style={{ marginLeft: 16 }}>
          <FormattedMessage id='shared.months'></FormattedMessage>
        </Typography>
        <Typography className={styles.text} style={{ marginRight: 16 }}>
          <FormattedMessage id='shared.days'></FormattedMessage>
        </Typography>
      </div>
    </div>
  )
}

export default AssesmentCountDown
