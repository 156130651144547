import React from 'react'
import { makeStyles } from '@mui/styles'

import { Box, CardActionArea, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  HeaderEntry_MainContainer: {
    width: '80%',
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(1),
    opacity: 0,
    animationName: 'appear',
    animationTimingFunction: 'linear',
    animationDuration: '0.2s',
    animationFillMode: 'forwards',
  },
  HeaderEntry_MainContainer__Active: {
    backgroundColor: theme.palette.primary.main,
  },
  HeaderEntry_Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  HeaderEntry_Icon: {
    fontSize: '32px',
    [theme.breakpoints.down(430)]: {
      fontSize: '28px',
    },
  },
  HeaderEntry__InActive: {
    color: theme.palette.secondary.gray,
  },
  HeaderEntry__Active: {
    color: 'white',
  },
  HeaderEntry_Text: {
    paddingLeft: theme.spacing(2),
    fontSize: 20,
  },
}))

const MobileHeaderEntry = ({ Icon, path, handleNavigation, title, index }) => {
  const c = useStyles()
  const history = useHistory()
  const { pathname } = history.location
  return (
    <Box
      className={clsx(
        c.HeaderEntry_MainContainer,
        pathname === path ? c.HeaderEntry_MainContainer__Active : null
      )}
      style={{ animationDelay: `${(index + 1) * 0.2}s` }}
    >
      <CardActionArea
        className={c.HeaderEntry_Container}
        onClick={() => handleNavigation(path)}
      >
        <Icon
          className={clsx(
            c.HeaderEntry_Icon,
            pathname === path ? c.HeaderEntry__Active : c.HeaderEntry__InActive
          )}
        ></Icon>
        <Typography
          className={clsx(
            c.HeaderEntry_Text,
            pathname === path ? c.HeaderEntry__Active : c.HeaderEntry__InActive
          )}
        >
          <FormattedMessage id={title}></FormattedMessage>
        </Typography>
      </CardActionArea>
    </Box>
  )
}

export default MobileHeaderEntry
