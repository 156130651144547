import React, { useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Pagination from '@mui/material/Pagination'
import PlakosLoading from '@components/atoms/PlakosLoading'

import BlockWithIcon from '../atoms/BlockWithIcon'
import { FormattedMessage, useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import IntroductionCard from './IntroductionCard'
import { Backdrop, Button } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetCourse } from '@pages/Course/slice'
import {
  getDashboard,
  getDashboardSilent,
  selectDashboardStatus,
} from '@pages/Dashboard/slice'
import { updateFreeCourseEnrollment } from '@pages/Shop/slice'
import MessageFromHero from '@components/atoms/MessageFromHero'
import DashboardReloadButton from '@components/atoms/DashboardReloadButton'
import {
  resetCourseTreeProgress,
  updateCourseTreeProgress,
} from '@services/courseOverview/slice'

const useStyles = makeStyles(theme => ({
  CardCourses: {
    marginBottom: 16,
    minHeight: 214,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_StatusContainer: {
    minHeight: 214,
  },
  CardCourses_Title: {
    padding: '16px',
    fontSize: '34px',
    lineHeight: '45px',
    paddingBottom: '8px',
    [theme.breakpoints.down(550)]: {
      fontSize: '20px',
      lineHeight: '26px',
      padding: '8px 16px',
    },
  },
  CardCourses_CardsWrapper: {
    padding: '0 16px',
    width: '100%',
  },
  CardCourses_ItemContainerWrapper: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
    },
  },
  CardCourses_ItemContainer: {
    width: '100%',
    marginBottom: '16px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_PaginationContainer: {
    marginBottom: 16,
  },
  CardCourses_PaginationWrapper: {
    '&>nav>ul>li>button.MuiPaginationItem-root': {
      [theme.breakpoints.down(550)]: {
        height: '26px',
        margin: '0 1px',
        padding: '0 4px',
        minWidth: '26px',
        borderRadius: '13px',
        fontSize: '14px',
      },
    },
  },
  CardCourses_HiddenBox: {
    width: '641px',
  },
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  CardCourses_ResetConfirm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    margin: '8px',
  },
  CardCourses_ResetButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  CardCourses_ResetConfirmText: {
    fontSize: '18px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  CardCourses_ResetConfirmButton: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    paddingTop: '8px',
    textTransform: 'uppercase',
  },
  CardCourses_MessageWrapper: {
    padding: theme.spacing(2),
  },
}))

const ITEMS_ON_PAGE = 10

const getText = (lessons = []) => lessons.map(item => item?.title).join(' - ')

export default function CardCourses({ data, status }) {
  const c = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [courseToReset, setCourseToReset] = useState(null)

  const resetHandler = () => {
    dispatch(resetCourse(courseToReset?.id))
    setTimeout(() => dispatch(getDashboard()), 500)
    dispatch(resetCourseTreeProgress(courseToReset?.id))
    setCourseToReset(null)
  }

  const { courses = [] } = data
  const coursesLength = courses.length

  const [page, setPage] = React.useState(1)
  const [countPages, setCountPages] = React.useState(0)

  useEffect(() => {
    setCountPages(Math.floor(coursesLength / ITEMS_ON_PAGE))
  }, [coursesLength])

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Card className={c.CardCourses} square elevation={0}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          flexDirection='row'
          flexWrap='nowrap'
        >
          <Typography className={c.CardCourses_Title}>
            <FormattedMessage id='shared.yourCourses' />
          </Typography>
          <DashboardReloadButton></DashboardReloadButton>
        </Grid>
        {status === STATUSES.LOADING && (
          <Grid
            className={c.CardCourses_StatusContainer}
            container
            justifyContent='center'
            alignItems='center'
          >
            <PlakosLoading />
          </Grid>
        )}
        {status === STATUSES.ERROR && (
          <Grid
            className={c.CardCourses_StatusContainer}
            container
            justifyContent='center'
            alignItems='center'
          >
            <Typography component='p'>
              <FormattedMessage id='shared.somethingWrong' />
            </Typography>
          </Grid>
        )}
        {status === STATUSES.LOADED && (
          <Box>
            <IntroductionCard notExpandable={true}></IntroductionCard>
            {courses.length === 0 ? (
              <div className={c.CardCourses_MessageWrapper}>
                <MessageFromHero
                  message={intl.formatMessage(
                    {
                      id: 'shared.noOwnedCourses',
                    },
                    {
                      shop: (
                        <a href={`${document.location.origin}/shop`}>
                          {intl.formatMessage({ id: 'shop.maintitle' })}
                        </a>
                      ),
                    }
                  )}
                ></MessageFromHero>
              </div>
            ) : (
              <Grid
                container
                wrap='wrap'
                justifyContent='space-between'
                className={c.CardCourses_CardsWrapper}
              >
                {courses
                  .slice(page * ITEMS_ON_PAGE - ITEMS_ON_PAGE, page * ITEMS_ON_PAGE)
                  .map(course => (
                    <Grid
                      item
                      key={course?.id}
                      className={c.CardCourses_ItemContainerWrapper}
                    >
                      <Paper className={c.CardCourses_ItemContainer} square>
                        <BlockWithIcon
                          id={course?.id}
                          icon={course?.icon}
                          title={course?.title}
                          progress={course?.progress}
                          text={getText(course?.lessons)}
                          isFree={course?.courseIsFree}
                          resetHandler={() => setCourseToReset(course)}
                          isUserSubscribed={course.userIsSubscribedToFree}
                          unSubscribeHanlder={() =>
                            dispatch(
                              updateFreeCourseEnrollment(
                                course?.id,
                                course.userIsSubscribedToFree
                              )
                            )
                          }
                        />
                      </Paper>
                    </Grid>
                  ))}
                {coursesLength % 2 === 1 && (
                  <Box className={c.CardCourses_HiddenBox}></Box>
                )}
              </Grid>
            )}
            {courses.length > ITEMS_ON_PAGE && (
              <Grid
                className={c.CardCourses_PaginationContainer}
                container
                justifyContent='center'
              >
                <Grid item className={c.CardCourses_PaginationWrapper}>
                  <Pagination
                    count={countPages + 1}
                    page={page}
                    onChange={handleChange}
                    color='primary'
                    size='large'
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Card>
      <Backdrop style={{ zIndex: 2 }} open={!!courseToReset}>
        <Paper className={c.CardCourses_ResetConfirm}>
          <Typography className={c.CardCourses_ResetConfirmText}>
            {intl.formatMessage(
              { id: 'shared.resetTopicConfirm' },
              { title: <b>{courseToReset?.title}</b> }
            )}
          </Typography>
          <div className={c.CardCourses_ResetButtonContainer}>
            <Button onClick={() => setCourseToReset(null)}>
              <Typography className={c.CardCourses_ResetConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.no' })}
              </Typography>
            </Button>
            <Button onClick={resetHandler}>
              <Typography className={c.CardCourses_ResetConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.yes' })}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Backdrop>
    </>
  )
}
