import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ProgressCardWithLevel from '@components/atoms/ProgressCardWithLevel'
import { resetCourse } from '@pages/Course/slice'
import NavigationCard from './NavigationCard'
import { Grid } from '@mui/material'
import { getNextContentNode, getPreviousContentNode } from '@services/courseCache'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  Container: {
    marginTop: theme.spacing(4),
  },
  Item_First: {
    order: 1,
    [theme.breakpoints.down(960)]: {
      marginBottom: theme.spacing(2),
      flex: '0 0 calc(50% - 8px)',
      maxWidth: 'calc(50% - 8px)',
    },
  },
  Item_Second: {
    order: 2,
    [theme.breakpoints.down(960)]: {
      order: 3,
    },
  },
  Item_Third: {
    order: 3,
    [theme.breakpoints.down(960)]: {
      order: 2,
      marginBottom: theme.spacing(2),
      flex: '0 0 calc(50% - 8px)',
      maxWidth: 'calc(50% - 8px)',
    },
  },
}))

const NavigationCardFooter = ({ content, currentContent }) => {
  const [neighborContent, setNeighborContent] = useState({})
  const { id, progress, progressData, title } = content
  const { nextContent, previousContent } = neighborContent
  useEffect(() => {
    const merged = {
      nextContent: getNextContentNode(currentContent),
      previousContent: getPreviousContentNode(currentContent),
    }
    setNeighborContent(merged)
  }, [currentContent])

  const intl = useIntl()
  const styles = useStyles()

  const history = useHistory()

  const dispatch = useDispatch()

  const textForTest = test => {
    return (
      (test?.surroundingLesson?.title ? test.surroundingLesson.title : '') +
      (test?.surroundingTopic?.title ? ` - ${test.surroundingTopic.title}` : '')
    )
  }

  const resetProgress = () => {
    dispatch(resetCourse(id))
  }

  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className={styles.Container}
    >
      <Grid item md={3} className={styles.Item_First}>
        {previousContent && (
          <NavigationCard
            typeOfTest={intl.formatMessage({ id: 'shared.previous' })}
            title={previousContent?.title}
            text={textForTest(previousContent)}
            progress={previousContent?.progress}
            buttonText={intl.formatMessage({ id: 'shared.start' })}
            buttonLink={`/${previousContent?.type}/${id}/${previousContent?.id}`}
            align={'right'}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.Item_Second}>
        <ProgressCardWithLevel
          title={title}
          progressData={{ ...progressData, progress: progress }}
        />
      </Grid>
      <Grid item md={3} className={styles.Item_Third}>
        {nextContent && (
          <NavigationCard
            typeOfTest={intl.formatMessage({ id: 'shared.next' })}
            title={nextContent?.title}
            text={textForTest(nextContent)}
            progress={nextContent?.progress}
            buttonText={intl.formatMessage({ id: 'shared.start' })}
            buttonLink={`/${nextContent.type}/${id}/${nextContent?.id}`}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default NavigationCardFooter
