import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const PREMIUM_CONTENT_POST_ID = 1056083

export const premiumOfferSlice = createSlice({
    name: 'premiumOffer',
    initialState: {
        premiumContent: null,
        premiumStatus: STATUSES.INIT,
    },
    reducers: {
        loading: state => ({...state, premiumStatus: STATUSES.LOADING }),
        loaded: (state, action) => ({
            ...state,
            premiumStatus: STATUSES.LOADED,
            premiumContent: action.payload,
        }),
        error: state => ({...state, premiumStatus: STATUSES.ERROR }),
    },
})

export const getPremiumOffer = () => dispatch => {
    dispatch(loading())
    axios({
        url: `https://kurse.plakos-akademie.de/wp-json/wp/v2/posts/${PREMIUM_CONTENT_POST_ID}`,
        Method: 'GET',
        'Content-Type': 'application/json',
    }).then(response => {
        if (response.status === 200) {
            dispatch(loaded(response.data))
        }
    })
}

export const selectPremiumOffer = state => state.premiumOffer.premiumContent
export const selectPremiumOfferStatus = state => state.premiumOffer.premiumStatus

export const { loading, loaded, error } = premiumOfferSlice.actions
export default premiumOfferSlice.reducer