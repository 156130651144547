import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState: {
    value: {},
    status: STATUSES.INIT,
  },
  reducers: {
    loaded: (state, { payload }) => ({
      ...state,
      value: payload,
      status: STATUSES.LOADED,
    }),
    loading: state => ({ ...state, status: STATUSES.LOADING }),
    error: state => ({ ...state, status: STATUSES.ERROR }),
  },
})

export const getLesson = (courseId, lessonId) => dispatch => {
  dispatch(loading())
  api({ endpoint: `/lessons/${courseId}/${lessonId}` })
    .then(res => {
      dispatch(loaded(res.data))
    })
    .catch(() => dispatch(error()))
}

export const selectLesson = state => state.lesson.value
export const selectLessonStatus = state => state.lesson.status

export const { loaded, loading, error } = lessonSlice.actions
export default lessonSlice.reducer
