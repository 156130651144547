import React, { useEffect, useState } from 'react'

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'

import { useParams } from 'react-router-dom'

import SideHeader from '@components/molecules/SideHeader'
import { useLocation } from 'react-router'
import IframeResizer from 'iframe-resizer-react'
import DOMPurify from 'dompurify'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getNotepad, selectNotepad, selectNotepadStatus } from './slice'
import NavigationCard from '../../@components/molecules/NavigationCard'
import { STATUSES } from '../../@constants/slices'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import {
  getCourseTree,
  selectCourseTree,
  selectCourseTreeStatus,
  setCurrentContent,
} from '@services/courseOverview/slice'
import { Typography } from '@mui/material'
import FENIntegration from '@components/atoms/FENIntegration'
import { selectGAStatus } from '@services/gaIntegration/slice'
import { PAGE_TITLE_TYPES, setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  Test: {},
  Test_ButtonContainer: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  Test_Introduction: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& iframe': {
      maxWidth: '100%',
      height: '600px',
      [theme.breakpoints.down(1300)]: {
        height: '340px',
      },
      [theme.breakpoints.down(960)]: {
        height: '440px',
      },
      [theme.breakpoints.down(800)]: {
        height: '360px',
      },
      [theme.breakpoints.down(600)]: {
        height: '270px',
      },
      [theme.breakpoints.down(400)]: {
        height: '220px',
      },
    },
  },
  Test_Iframe: {
    minWidth: '100%',
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  Test_Iframe__Loading: {
    display: 'none',
  },
  Test_LoadingFrame: {
    backgroundColor: 'white',
    minHeight: '100px',
  },
  Test_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Notepad_TestCardWrapper: {
    margin: theme.spacing(1),
    flex: `0 0 calc(50% - ${theme.spacing(2)})`,
    maxWidth: `calc(50% - ${theme.spacing(2)})`,
  },
  Notepad_TestCards: {
    width: '100%',
  },
  Notepad_TestCards_Title: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontSize: 24,
  },
}))

const LeftSideTest = ({ testLink, courseTree }) => {
  const intl = useIntl()

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })
  return (
    <>
      <SideHeader
        content={{
          title: intl.formatMessage({ id: 'shared.notepad' }),
          surroundingContent: courseTree,
          type: intl.formatMessage({ id: 'shared.test' }),
        }}
        courseId={courseTree.id}
      />
      <FENIntegration testLink={testLink}></FENIntegration>
    </>
  )
}

const Notepad = () => {
  const c = useStyles()
  const dispatch = useDispatch()
  const [isTestStarted, setIsTestStarted] = useState(false)
  const { courseId } = useParams()
  const intl = useIntl()
  const location = useLocation()
  const courseStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const notepad = useSelector(selectNotepad)
  const notepadStatus = useSelector(selectNotepadStatus)
  const isActive = useSelector(selectGAStatus)
  const status =
    courseStatus === STATUSES.LOADED && notepadStatus === STATUSES.LOADED
      ? STATUSES.LOADED
      : courseStatus === STATUSES.ERROR || notepadStatus === STATUSES.ERROR
        ? STATUSES.ERROR
      : STATUSES.LOADING

  useEffect(() => {
    setPageTitle(PAGE_TITLE_TYPES.NOTEPAD)
  }, [isActive])

  useEffect(() => {
    dispatch(getNotepad(courseId))
    if (courseStatus === STATUSES.INIT) {
      dispatch(getCourseTree(courseId))
    }
  }, [dispatch, courseId])

  useEffect(() => {
    if (status === STATUSES.LOADED) {
      dispatch(setCurrentContent(notepad))
    }
  }, [status])

  const hadleTestButton = () => {
    setIsTestStarted(prevState => !prevState)
  }

  return (
    <>
      <LayoutWithoutImage
        status={status}
        courseTree={courseTree}
        currentContent={notepad}
      >
        <LeftSideTest
          testLink={notepad?.testLink}
          courseTree={courseTree}
          hadleTestButton={hadleTestButton}
          isTestStarted={isTestStarted}
        />
        {notepadStatus === STATUSES.LOADED && (
          <Grid
            container
            className={c.Notepad_TestCardsContainer}
            justifyContent='center'
            direction='column'
          >
            <Typography className={c.Notepad_TestCards_Title} component='div'>
              <FormattedMessage id='notepad.testCards'></FormattedMessage>
            </Typography>
            <Grid container item className={c.Notepad_TestCards}>
              {notepad.tests.map(test => (
                <Grid item key={test.id} className={c.Notepad_TestCardWrapper}>
                  <NavigationCard
                    typeOfTest={intl.formatMessage({ id: 'shared.test' })}
                    title={test?.title}
                    text={
                      test?.surroundingLesson?.title ? test.surroundingLesson.title : ''
                    }
                    progress={test?.progress}
                    buttonText={intl.formatMessage({ id: 'shared.start' })}
                    buttonLink={`/test/${courseId}/${test?.id}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </LayoutWithoutImage>
    </>
  )
}

export default Notepad
